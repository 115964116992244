import React from "react";
import "./LogisticsMapView.css";

const LogisticsMapView = ({
  facility_name,
  address,
  phone,
  business_phone,
  website,
  email,
  onClick,
}) => {
  const linkStyle = { color: "#0066cc", textDecoration: "none" };

  // Helper function to handle the website URL
  const handleWebsiteURL = (url) => {
    return url.startsWith("http://") || url.startsWith("https://")
      ? url
      : `https://${url}`;
  };

  return (
    <div className="card-logistics" onClick={onClick}>
      <div className="card-container-logistics-internal">
        <h4 style={{ fontSize: "18px" }}>{facility_name}</h4>
      </div>

      <div>📍 {address}</div>

      <div className="card-same-rows">
        {/* Clickable phone number */}
        {phone && (
          <div>
            📞{" "}
            <a href={`tel:${phone}`} style={linkStyle}>
              {phone}
            </a>
          </div>
        )}

        {business_phone && (
          <div>
            📠{" "}
            <a href={`tel:${business_phone}`} style={linkStyle}>
              {business_phone}
            </a>
          </div>
        )}
      </div>

      <div className="card-same-rows">
        {/* Clickable email */}
        {email && (
          <div className="nextline-view">
            ✉️{" "}
            <a href={`mailto:${email}`} style={linkStyle}>
              {email}
            </a>
          </div>
        )}

        {/* Clickable website */}
        {website && (
          <div className="nextline-view">
            🌐{" "}
            <a
              href={handleWebsiteURL(website)}
              target="_blank"
              rel="noreferrer"
              style={linkStyle}
            >
              {website}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default LogisticsMapView;
