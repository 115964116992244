import React from "react";
import "./LogisticsMapView.css";

const LogisticsMapViewGrid = ({
  facility_name,
  address,
  phone,
  business_phone,
  website,
  email,
  onClick,
}) => {
  return (
    <div className="card-logistics-grid" onClick={onClick}>
      <div className="card-container-logistics-internal">
        <h4 style={{ fontSize: "18px" }}>{facility_name}</h4>
      </div>

      <div>📍 {address}</div>

      {/* Clickable phone number */}
      <div>
        📞{" "}
        <a
          href={`tel:${phone}`}
          style={{ color: "#0066cc", textDecoration: "none" }}
        >
          {phone}
        </a>
      </div>

      {business_phone && (
        <div>
          📠{" "}
          <a
            href={`tel:${business_phone}`}
            style={{ color: "#0066cc", textDecoration: "none" }}
          >
            {business_phone}
          </a>
        </div>
      )}

      {/* Clickable email */}
      {email && (
        <div className="nextline-view">
          ✉️{" "}
          <a
            href={`mailto:${email}`}
            style={{ color: "#0066cc", textDecoration: "none" }}
          >
            {email}
          </a>
        </div>
      )}

      {website && (
        <div className="nextline-view">
          🌐{" "}
          <a
            href={
              website.startsWith("http://") || website.startsWith("https://")
                ? website
                : `https://${website}`
            }
            target="_blank"
            rel="noreferrer"
            style={{ color: "#0066cc", textDecoration: "none" }}
          >
            {website}
          </a>
        </div>
      )}
    </div>
  );
};

export default LogisticsMapViewGrid;
