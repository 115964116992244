/* eslint-disable no-useless-escape */
import * as Yup from "yup";
import { languageJson } from "../StringVariable";

const passwordRegExp =
  /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
export const idValidation = /^[0-9A-Za-z\-]+$/;
export const numberValidation = /\D/;
export const companyValidation = /^[a-zA-Z0-9\s,.\-&']{2,100}$/;
export const alphabetValidation = /^[a-zA-Z\s\-]*$/;
export const isValidPhone = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;
export const isValidLicenceNumber = /^[a-zA-Z0-9-\d*]*$/;
export const isValidContactPersonName = /^[a-zA-Z\s\-' ]*$/;
export const isValidName = /^[a-zA-Z\s\- ]*$/;
export const isValidStateOfInsurance = /^[0-9a-zA-Z\s\-]*$/;
export const isValid = /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-\s\-]*$/i;
export const isLetterNoSpace = /^[a-zA-Z]+$/;
export const isNumberNoSpace = /^\d+$/;

// Public register validation
export const registerSchema = Yup.object().shape({
  first_name: Yup.string()
    .required(languageJson.firstname_required_txt)
    .matches(alphabetValidation, languageJson.firstname_Not_valid_txt)
    .trim()
    .min(1, languageJson.firstname_1characters_txt),
  last_name: Yup.string()
    .required(languageJson.lastname_required_txt)
    .matches(alphabetValidation, languageJson.lastname_Not_valid_txt)
    .trim()
    .min(1, languageJson.lastname_1characters_txt),
  mobile: Yup.string()
    .required(languageJson.mobileNumber_required_txt)
    .matches(isValidPhone, languageJson.mobileNumbe_valid_txt),
  user_type_value: Yup.string()
    .required(languageJson.usertype_required_txt)
    .trim(),
  email: Yup.string()
    .required(languageJson.email_required_txt)
    .trim()
    .email(languageJson.emailInvalid_txt),
  password: Yup.string()
    .required(languageJson.registration_password_error_txt)
    .matches(passwordRegExp, languageJson.registration_password_error_txt),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], languageJson.passwords_not_matched_txt)
    .required(languageJson.ConfirmPassword_required_txt),
  userAgreement: Yup.boolean()
    .required(languageJson.tnc_alert_text_txt)
    .oneOf([true], languageJson.tnc_alert_text_txt),
  dl_or_companyid_number: Yup.string()
    .required(languageJson.DL_CompID_required_txt)
    .matches(isValidLicenceNumber, languageJson.CDLTWIC_notValid_txt)
    .trim(),
  company_name: Yup.string().matches(
    isValidName,
    languageJson.companyname_Not_valid_txt
  ),
  job_title: Yup.string().matches(
    isValidName,
    languageJson.jobTitle_Not_valid_txt
  ),
});

// Driver Registration
export const registerDriverSchema = Yup.object().shape({
  first_name: Yup.string()
    .required(languageJson.firstname_required_txt)
    .matches(alphabetValidation, languageJson.firstname_Not_valid_txt)
    .trim()
    .min(1, languageJson.firstname_1characters_txt),
  last_name: Yup.string()
    .required(languageJson.lastname_required_txt)
    .matches(alphabetValidation, languageJson.lastname_Not_valid_txt)
    .trim()
    .min(1, languageJson.lastname_1characters_txt),
  mobile: Yup.string()
    .required(languageJson.mobileNumber_required_txt)
    .matches(isValidPhone, languageJson.mobileNumbe_valid_txt),
  email: Yup.string()
    .required(languageJson.email_required_txt)
    .trim()
    .email(languageJson.emailInvalid_txt),
  password: Yup.string()
    .required(languageJson.registration_password_error_txt)
    .matches(passwordRegExp, languageJson.registration_password_error_txt),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], languageJson.passwords_not_matched_txt)
    .required(languageJson.ConfirmPassword_required_txt),
  userAgreement: Yup.boolean()
    .required(languageJson.tnc_alert_text_txt)
    .oneOf([true], languageJson.tnc_alert_text_txt),
  dl_or_companyid_number: Yup.string()
    .required(languageJson.DL_CompID_required_txt)
    .matches(isValidLicenceNumber, languageJson.CDLTWIC_notValid_txt)
    .trim(),
  company_name: Yup.string().matches(
    isValidName,
    languageJson.companyname_Not_valid_txt
  ),
  job_title: Yup.string().matches(
    isValidName,
    languageJson.jobTitle_Not_valid_txt
  ),
  state: Yup.string()
    .required(languageJson.state_insurance_required_txt)
    .matches(isValidStateOfInsurance, languageJson.state_insurance_invalid_txt)
    .trim(),
  twicCardNumber: Yup.string()
    .required(languageJson.twic_card_number_txt)
    .trim()
    .matches(isNumberNoSpace, languageJson.Not_NumberWithoutSpace_txt)
    .min(9, languageJson.twic_card_number_short_txt)
    .max(9, languageJson.twic_card_number_long_txt),
});

// LMC Personal data validation
export const primaryLMCSchema = Yup.object().shape({
  lmc_name: Yup.string()
    .required(languageJson.name_required_txt)
    .matches(companyValidation, languageJson.LMCname_Not_valid_txt)
    .trim()
    .min(3, languageJson.name_required_3characters_txt),
  // company_established_date: Yup.string()
  //     .required(languageJson.company_established_date),
  lmc_type: Yup.string().required(languageJson.LMCtype_required_txt).trim(),
  company_office_address: Yup.string()
    .required(languageJson.CompanyAddress_required_txt)
    .trim()
    .min(3, languageJson.CompanyAddress_required_3characters_txt),
  business_phone_number: Yup.string()
    .required(languageJson.BusinessPhone_required_txt)
    .matches(isValidPhone, languageJson.Phone_vaild_txt),
  business_email: Yup.string()
    .required(languageJson.business_email_required_txt)
    .trim()
    .email(languageJson.emailInvalid_txt),
  secondary_email: Yup.string()
    // .notOneOf([Yup.ref('business_email'), null], languageJson.email_shouldNotSame_txt) TODO 30 JULY
    .notRequired()
    .email(languageJson.emailInvalid_txt),
  password: Yup.string()
    .required(languageJson.registration_password_error_txt)
    .matches(passwordRegExp, languageJson.registration_password_error_txt),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], languageJson.passwords_not_matched_txt)
    .required(languageJson.ConfirmPassword_required_txt),
  userAgreement: Yup.boolean()
    .required(languageJson.tnc_alert_text_txt)
    .oneOf([true], languageJson.tnc_alert_text_txt),
});

// LMC Other data validation
export const otherLMCSchema = Yup.object().shape({
  contact_person_name: Yup.string()
    .required(languageJson.PersonName_required_txt)
    .matches(
      isValidContactPersonName,
      languageJson.Not_valid_contactPersonName_txt
    )
    .trim()
    .min(2, languageJson.name_required_3characters_txt),
  contact_person_number: Yup.string()
    .required(languageJson.Phone_required_txt)
    .matches(isValidPhone, languageJson.Phone_vaild_txt),
  dot_number: Yup.string()
    .required(languageJson.DOTNumber_required_txt)
    .trim()
    // .matches(isNumberNoSpace, languageJson.Not_validCharacter_txt)
    .min(6, languageJson.DOTNumber_short_txt)
    .max(8, languageJson.DOTNumber_long_txt), //TODO 23 July
  us_mc_number: Yup.string()
    .required(languageJson.US_MCNumber_required_txt)
    .trim()
    .matches(isNumberNoSpace, languageJson.Not_NumberWithoutSpace_txt)
    .min(6, languageJson.US_MCNumber_short_txt)
    .max(7, languageJson.US_MCNumber_long_txt),
  state_mc_number: Yup.string()
    .required(languageJson.MCnumber_required_txt)
    .matches(isNumberNoSpace, languageJson.Not_NumberWithoutSpace_txt)
    .trim()
    .min(5, languageJson.State_MCnumber_short_txt)
    .max(7, languageJson.State_MCnumber_long_txt),
  standard_carrier_alpha_code: Yup.string()
    .required(languageJson.Standard_carriercode_required_txt)
    .matches(isLetterNoSpace, languageJson.Not_LetterWithoutSpace_txt)
    .trim()
    .min(2, languageJson.Standard_carriercode_short_txt)
    .max(4, languageJson.Standard_carriercode_long_txt),
  document_name: Yup.string()
    .required(languageJson.DocumentType_required_txt)
    .nullable()
    .typeError(languageJson.PleaseSelectDoctype_txt),
});

// Login validation
export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .required(languageJson.email_required_txt)
    .email(languageJson.emailInvalid_txt),
  password: Yup.string().required(languageJson.Password_required_txt),
});

// Contact Us validation
export const contactUsSchema = Yup.object().shape({
  first_name: Yup.string()
    .required(languageJson.firstname_required_txt)
    .matches(alphabetValidation, languageJson.firstname_Not_valid_txt)
    .trim()
    .min(1, languageJson.firstname_1characters_txt),
  last_name: Yup.string()
    .required(languageJson.lastname_required_txt)
    .matches(alphabetValidation, languageJson.lastname_Not_valid_txt)
    .trim()
    .min(1, languageJson.lastname_1characters_txt),
  email: Yup.string()
    .required(languageJson.email_required_txt)
    .trim()
    .email(languageJson.emailInvalid_txt),
  description: Yup.string()
    .required(languageJson.Message_required_txt)
    .trim()
    .min(3, languageJson.Message_required_txt)
    .max(1024, languageJson.Message_required_txt),
});

// Forgot Password validation
export const forgotSchema = Yup.object().shape({
  email: Yup.string()
    .required(languageJson.email_required_txt)
    .trim()
    .email(languageJson.emailInvalid_txt),
});

//Change Password validation
export const changePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required(languageJson.registration_password_error_txt)
    .matches(passwordRegExp, languageJson.registration_password_error_txt),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], languageJson.passwords_not_matched_txt)
    .required(languageJson.ConfirmPassword_required_txt),
});

// Form validations

export const isRequired = (temp) => {
  if (
    temp == null ||
    temp === undefined ||
    temp === "" ||
    (temp.length > 0 && temp.trim().length < 1)
  ) {
    return true;
  } else return false;
};

export const isValidEmail = (temp) => {
  if (typeof temp !== "undefined" && temp !== "") {
    // var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    var pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
    if (!pattern.test(temp)) {
      return "Email is invalid";
    }
  }
};

export const isValidUrl = (temp) => {
  if (typeof temp !== "undefined") {
    var pattern = new RegExp(
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g
    );
    if (!pattern.test(temp)) {
      return "Please enter a valid URL";
    }
  }
};

export const isValidAlphabet = (temp) => {
  if (typeof temp !== "undefined" && temp !== "") {
    var pattern = new RegExp(/^[a-zA-Z\s\- ]*$/);
    if (!pattern.test(temp)) {
      return "Invalid";
    }
  }
};

export const isValidCompanyName = (temp) => {
  if (typeof temp !== "undefined" && temp !== "") {
    var pattern = new RegExp(/^[a-zA-Z0-9\s,.\- &']{2,100}$/);
    if (!pattern.test(temp)) {
      return "Invalid";
    }
  }
};
export const isValidPhoneNumber = (temp) => {
  if (typeof temp !== "undefined" && temp !== "") {
    var pattern = new RegExp(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/);
    if (!pattern.test(temp)) {
      return "Moblie no not valid";
    }
  }
};
export const isValidPhoneNumberNew = (temp) => {
  if (typeof temp !== "undefined" && temp !== "") {
    var pattern = new RegExp(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/);
    if (pattern.test(temp)) {
      return "Mobile no not valid";
    }
  }
};
export const priceFormate = (value) => {
  if (value != null && value > 0) {
    return (value / 100).toFixed(2);
  } else {
    return 0;
  }
};

export const imageType = (value) => {
  if (
    value !== "undefined" &&
    value !== "" &&
    (value === "image/png" || value === "image/jpeg" || value === "image/gif")
  ) {
    return false;
  } else return true;
};

export const isvalidateDOTNumber = (temp) => {
  if (typeof temp !== "undefined" && temp !== "") {
    var pattern = new RegExp(/^\d{6,8}$/);
    if (!pattern.test(temp)) {
      return "DOT number is not valid";
    }
  }
};

export const isValidFullName = (name) => {
  // Check if name is defined and not empty
  if (typeof name !== "undefined" && name !== "") {
    var namePattern = new RegExp(/^[A-Za-z'.-]+(?: [A-Za-z'.-]+)*$/);
    // If name doesn't match the pattern, return invalid message
    if (!namePattern.test(name)) {
      return "Full name is not valid";
    }
  }
};
