import React from "react";
import { Spinner } from "react-bootstrap";

export default function SpinerLoader() {
  return (
    <div
      className="row preloaderNew"
      style={{
        textAlign: "center",
        display: "block",
        position: "relative",
        maxHeight: "10rem",
      }}
    >
      <Spinner animation="border" />
    </div>
  );
}
