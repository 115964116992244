/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import Header from "../../Containers/Header";
import { Link } from "react-router-dom";
import { languageJson } from "../../StringVariable";
import "./RailwayCrossing.css";
import { submitGAEvent } from "src/utils/GAEvents";
import portimg1 from "../../assets/VesselOperations/1.jpg";
import portimg2 from "../../assets/VesselOperations/2.jpg";
import portimg3 from "../../assets/VesselOperations/3.jpg";
import OceanServicesPDF from "../Vessel_Operations/OceanServicesPDF";


function OaklandOceanCarrierServices() {
  useEffect(() => {
    submitGAEvent("Oakland Ocean Carrier Services");
  }, []);

  return (
    <div>
      <Header />

      <div className="image-row-banner">
        <img className="banner_img" src={portimg1} alt="" />
        <img className="banner_img" src={portimg3} alt="" />
        <img className="banner_img" src={portimg2} alt="" />
      </div>
      <div className="vo-bg">
        <div className="container-fluid row py-4 ">
          <div>
            <Link to="/">
              {" "}
              <span className="vo-menu pe-2">
                {languageJson.home_txt}{" "}
              </span>{" "}
            </Link>
            <span className="text-dark ps-2">
              {" "}
              / &nbsp; {languageJson.ocean_services_text}
            </span>
          </div>
        </div>
      </div>

      {/* ------------------------------------------------------ */}
      <div
        className="railCrossinglRoutesMainDiv"
        style={{ marginTop: "-100px" }}
      >
        <section className="railcrossing-head container py-5">
          <div className="row container-fluid py-5 tabVesselDiv">
            <div className="d-flex justify-content-center">
              <div className="truckTurnTimeMainDiv historicalTTTMainDiv">
                <div className="container">
                  <div className="titleDescpDiv">
                    <h2>{languageJson.ocean_services_text}</h2>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "50px" }}>
              <OceanServicesPDF /> 
            </div>
          </div>
        </section>
       
      </div>
     
    </div>
  );
}

export default OaklandOceanCarrierServices;
