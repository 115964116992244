import React from "react";
import "./ButtonComponent.css"; // Assuming you'll put your CSS here
import { languageJson } from "../../StringVariable";
import { useHistory } from "react-router-dom";

const OceanIconComponent = () => {
  const history = useHistory(); // Hook to get the history object

  const handleButtonClick = () => {
    // Navigate to a new route on button click
    history.push("/OaklandOceanCarrierServices"); // Replace with the desired route
  };

  return (
    <>
      <div
        className="holiday-button-container"
        style={{ justifyContent: "flex-start" }}
      >
        <button className="holiday-button" onClick={handleButtonClick}>
          <span className="text">{languageJson.ocean_services_text}</span>
          <div className="icon-container">
            <div className="icon icon--left">
              <svg>
                <use xlinkHref="#arrow-right"></use>
              </svg>
            </div>
            <div className="icon icon--right">
              <svg>
                <use xlinkHref="#arrow-right"></use>
              </svg>
            </div>
          </div>
        </button>
      </div>

      <svg style={{ display: "none" }}>
        <symbol id="arrow-right" viewBox="0 0 20 10">
          <path d="M14.84 0l-1.08 1.06 3.3 3.2H0v1.49h17.05l-3.3 3.2L14.84 10 20 5l-5.16-5z"></path>
        </symbol>
      </svg>
    </>
  );
};

export default OceanIconComponent;
