import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import en from "src/assets/json/en.json";
import sp from "src/assets/json/sp.json";
import pun from "src/assets/json/pun.json";

export default function SelectLanguageDisplay(lang1) {
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  let { userDetail } = useSelector((state) => state);

  useEffect(() => {
    if (userDetail && userDetail.userSetting != null) {
      localStorage.setItem("lang", userDetail.userSetting.language);
      setLang(userDetail.userSetting.language);
    } else {
      let lang = localStorage.getItem("lang");
      setLang(["es", "en", "pa"].includes(lang) ? lang : "en");
    }
  }, [userDetail.userSetting, lang]); // eslint-disable-line react-hooks/exhaustive-deps
  let token = localStorage.getItem("token");
  let user_type = JSON.parse(localStorage.getItem("user_type"));
  let istoken = token ? true : false;
  if (
    istoken &&
    (user_type.toLowerCase() === "superadmin" ||
      user_type.toLowerCase() === "admin")
  ) {
    return en;
  } else {
    switch (lang1) {
      case "en":
        return en;
      case "es":
        return sp;
      case "pa":
        return pun;
      default:
        return en;
    }
  }
}
