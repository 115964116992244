import React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
export const FAQFILE_MARINE_TERMINAL = `${window.location.origin}/Port_of_Oakland_Ocean_Carrier_Services_v17.pdf`;

// Add responsive styles using CSS-in-JS or external CSS
const OceanServicesPDF = () => {
  //const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div style={styles.container}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.10.111/build/pdf.worker.min.js">
        <Viewer
          fileUrl={FAQFILE_MARINE_TERMINAL} // Pass the variable directly without wrapping it in {}
          //  plugins={[defaultLayoutPluginInstance]}
        />
      </Worker>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // Makes the container take full height of the viewport
    width: "100%", // Ensures the container takes full width
    padding: "10px", // Optional: Add padding for small screens
    boxSizing: "border-box",
  },
};

export default OceanServicesPDF;
