import React, { useState } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  withScriptjs,
  withGoogleMap,
} from "react-google-maps";
import customMarkerImage from "../../assets/legend/custom-marker.png";
import customMarkerImage1 from "../../assets/legend/1.png";
import customMarkerImage2 from "../../assets/legend/2.png";
import customMarkerImage3 from "../../assets/legend/3.png";
import customMarkerImage4 from "../../assets/legend/4.png";
import customMarkerImage5 from "../../assets/legend/5.png";
import customMarkerImage6 from "../../assets/legend/6.png";
import { languageJson } from "../../StringVariable";
const {
  business_phone_number_text,
  facility_type,
  point_of_contact_text,
  additional_features_text,
  description_text,
  facility_phone_number,
  facility_email_id,
} = languageJson.LogisticsMap;

// Define initial latitude and longitude
const initialLat = 37.8337351;
const initialLng = -122.2379737;
const linkStyle = { color: "#0066cc", textDecoration: "none" };

// Helper function to handle the website URL
const handleWebsiteURL = (url) => {
  return url.startsWith("http://") || url.startsWith("https://")
    ? url
    : `https://${url}`;
};

const MapWithMarkers = withScriptjs(
  withGoogleMap(
    ({ data, selectedItem, setSelectedItem, setNewClass, activeList }) => {
      // const [selectedItem, setSelectedItem] = useState(null);
      const [mapCenter, setMapCenter] = useState({
        lat: initialLat,
        lng: initialLng,
      }); // Initial map center

      const handleMarkerClick = (item) => {
        setSelectedItem(item);
        // Move map center to the marker's location
        setMapCenter({
          lat: parseFloat(item.latitude),
          lng: parseFloat(item.longitude),
        });
        setNewClass(false);
      };
      // Define a function to map types to icons
      const getMarkerIcon = (type) => {
        switch (type) {
          case "Warehouse":
            return customMarkerImage3;
          case "Distribution Center": // Example for another type
            return customMarkerImage2;
          case "Warehouse-Distribution Center":
            return customMarkerImage1;
          case "Freight Forwarders":
            return customMarkerImage4;
          case "Preferred Partners":
            return customMarkerImage5;
          case "Other":
            return customMarkerImage6;
          default:
            return customMarkerImage; // Default marker image
        }
      };
      return (
        <GoogleMap
          defaultZoom={11}
          center={mapCenter} // Center the map based on mapCenter state
          options={{
            streetViewControl: false,
            gestureHandling: "cooperative", // Prevents unwanted map movements
          }}
          onClick={() => setSelectedItem(null)} // Close InfoWindow on map click
        >
          {data.map(
            (item, index) =>
              activeList.includes(item.type) && (
                <Marker
                  key={index}
                  position={{
                    lat: parseFloat(item.latitude),
                    lng: parseFloat(item.longitude),
                  }}
                  onClick={() => handleMarkerClick(item)} // Move center on marker click
                  icon={getMarkerIcon(item.type)}
                />
              )
          )}

          {selectedItem && (
            <InfoWindow
              position={{
                lat: parseFloat(selectedItem.latitude),
                lng: parseFloat(selectedItem.longitude),
              }}
              onCloseClick={() => setSelectedItem(null)}
            >
              <div className="mapPopup portMessageSignsMain-view">
                <button
                  className="closeBtnPopup"
                  onClick={() => setSelectedItem(null)}
                >
                  X
                </button>
                <div className="portMessageSignsMain-view">
                  <div className="labelTxt">
                    <h3 className="title-card-view">
                      {selectedItem.facility_name}
                    </h3>
                    <div className="scrollable-content-info">
                      <div className="dateTimeTxt">
                        📍 {selectedItem.address}{" "}
                      </div>

                      {/* Clickable phone number */}
                      <div className="dateTimeTxt">
                        📞{facility_phone_number} : {""}
                        <a
                          href={`tel:${selectedItem.phone}`}
                          style={{ color: "#0066cc", textDecoration: "none" }}
                        >
                          {selectedItem.phone}
                        </a>
                      </div>

                      {selectedItem.business_phone && (
                        <div className="dateTimeTxt">
                          📠 {business_phone_number_text} : {""}
                          <a
                            href={`tel:${selectedItem.business_phone}`}
                            style={{ color: "#0066cc", textDecoration: "none" }}
                          >
                            {selectedItem.business_phone}
                          </a>
                        </div>
                      )}
                      {/* Clickable email */}
                      {selectedItem.email && (
                        <div className="nextline-view">
                          ✉️{facility_email_id} : {""}
                          <a
                            href={`mailto:${selectedItem.email}`}
                            style={{ color: "#0066cc", textDecoration: "none" }}
                          >
                            {selectedItem.email}
                          </a>
                        </div>
                      )}
                      {selectedItem.website && (
                        <div className="nextline-view">
                          🌐{" "}
                          <a
                            href={handleWebsiteURL(selectedItem.website)}
                            target="_blank"
                            rel="noreferrer"
                            style={linkStyle}
                          >
                            {selectedItem.website}
                          </a>
                        </div>
                      )}
                      {/* {selectedItem && selectedItem.website && (
                      <div className="nextline-view">
                        🌐{" "}
                        <a
                          href={
                            selectedItem.website.startsWith("http://") ||
                            selectedItem.website.startsWith("https://")
                              ? selectedItem.website
                              : `https://${selectedItem.website}`
                          }
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "#0066cc", textDecoration: "none" }}
                        >
                          {selectedItem.website}
                        </a>
                      </div>
                    )} */}
                      {selectedItem.point_of_contact && (
                        <div className="dateTimeTxt">
                          👤 {point_of_contact_text} : {""}
                          {selectedItem.point_of_contact}
                        </div>
                      )}
                      <div className="dateTimeTxt">
                        📦 {facility_type} : {""} {selectedItem.type}
                      </div>
                      {selectedItem.additional_features && (
                        <div className="dateTimeTxt">
                          🛠️ {additional_features_text} : {""}
                          {selectedItem.additional_features}
                        </div>
                      )}
                      {selectedItem.description && (
                        <div className="dateTimeTxt">
                          📝 {description_text} : {""}{" "}
                          {selectedItem.description}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      );
    }
  )
);

export default MapWithMarkers;
