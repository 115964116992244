  import { Modal } from 'react-bootstrap';
import {DateTimeFormateSeconds } from "src/utils/DataFormate";




export default function DetailVesselOperationAdvent(props) {
  const { marinMapDetail } = props
  return (
    <Modal
      className="marineCameraModal"
      show={props.open}
      onHide={props.closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
    >
 
      <Modal.Body className="vesselOperationMainDetail">
        <button className="closeBtnPopup" onClick={() => { props.closeModal() }}>X</button>
        <div className="vesselInfoDiv " style={{ width: "100%" }}>
          <div className="row m-4">
          {marinMapDetail.terminalCode && <label>  <b>{"Terminal Code "} : </b> {marinMapDetail.terminalCode || "NOT AVAILABLE"}</label>}
          {marinMapDetail.terminalName && <label>  <b>{"Terminal Name "} : </b> {marinMapDetail.terminalName || "NOT AVAILABLE"}</label>}
          {marinMapDetail.vesselName && <label>  <b>{"Vessel Name "} : </b> {marinMapDetail.vesselName || "NOT AVAILABLE"}</label>}
          {marinMapDetail.lloydsCode && <label>  <b>{"Lloyds code "} : </b> {marinMapDetail.lloydsCode || "NOT AVAILABLE"}</label>}
          {marinMapDetail.vesselClass && <label>   <b>{"Vessel Class "} : </b> {marinMapDetail.vesselClass || "NOT AVAILABLE"}</label>} 
          {marinMapDetail.voyage && <label>   <b>{"Voyage "} : </b> {marinMapDetail.voyage || "NOT AVAILABLE"}</label>} 
          {marinMapDetail.berth && <label>   <b>{"Berth "} : </b> {marinMapDetail.berth || "NOT AVAILABLE"}</label>} 
          {marinMapDetail.publishDate && <label>   <b>{"Publish Date "} : </b> {(marinMapDetail.publishDate) || "NOT AVAILABLE"}</label>} 
          {marinMapDetail.expireDate && <label>   <b>{"Expire Date "} : </b> {(marinMapDetail.expireDate) || "NOT AVAILABLE"}</label>}   
          {marinMapDetail.inboundVoyage && <label>   <b>{"Inbound Voyage "} : </b> {marinMapDetail.inboundVoyage || "NOT AVAILABLE"}</label>} 
          {marinMapDetail.estimatedArrival && <label>   <b>{"Estimated Arrival "} :</b>  {marinMapDetail.estimatedArrival ? DateTimeFormateSeconds(marinMapDetail.estimatedArrival) : "NOT AVAILABLE"}</label>} 
          {marinMapDetail.actualArrival && <label>   <b>{ "Actual Arrival "} : </b> {marinMapDetail.actualArrival ? DateTimeFormateSeconds(marinMapDetail.actualArrival) : "NOT AVAILABLE"}</label>} 
          {marinMapDetail.firstAvailable && <label>   <b>{"First Available "} : </b> {marinMapDetail.firstAvailable || "NOT AVAILABLE"}</label>} 
            {marinMapDetail.cargoReceive && <label>   <b>{"Cargo Receive"} : </b> {marinMapDetail.cargoReceive || "NOT AVAILABLE"}</label>} 
            {marinMapDetail.cargoReceiveComment && <label>   <b>{"Cargo Receive Comment "} : </b> {marinMapDetail.cargoReceiveComment || "NOT AVAILABLE"}</label>} 
            {marinMapDetail.reeferReceive && <label>   <b>{"Reefer Receive "} : </b> {marinMapDetail.reeferReceive || "NOT AVAILABLE"}</label>} 
            {marinMapDetail.reeferReceiveComment && <label>   <b>{"Reefer Receive Comment "} : </b> {marinMapDetail.reeferReceiveComment || "NOT AVAILABLE"}</label>} 
            {marinMapDetail.hazmatReceive && <label>   <b>{"Hazmat Receive "} : </b> {marinMapDetail.hazmatReceive || "NOT AVAILABLE"}</label>} 
            {marinMapDetail.hazmatReceiveComment && <label>   <b>{"Hazmat Receive Comment "} : </b> {marinMapDetail.hazmatReceiveComment || "NOT AVAILABLE"}</label>} 
            {marinMapDetail.autoReceive && <label>   <b>{"Auto Receive"} : </b> {marinMapDetail.autoReceive || "NOT AVAILABLE"}</label>} 
            {marinMapDetail.autoReceiveComment && <label>   <b>{"Auto Receive Comment "} : </b> {marinMapDetail.autoReceiveComment || "NOT AVAILABLE"}</label>} 
            {marinMapDetail.outboundVoyage && <label>   <b>{"Outbound Voyage "} : </b> {marinMapDetail.outboundVoyage || "NOT AVAILABLE"}</label>} 
            {marinMapDetail.estimatedDeparture && <label>   <b>{"Estimated Departure "} : </b> {marinMapDetail.estimatedDeparture ? DateTimeFormateSeconds(marinMapDetail.estimatedDeparture) : "NOT AVAILABLE"}</label>} 
            {marinMapDetail.actualDeparture && <label>   <b>{"Actual Departure "} : </b> {marinMapDetail.actualDeparture ? DateTimeFormateSeconds(marinMapDetail.actualDeparture) : "NOT AVAILABLE"}</label>} 
            {marinMapDetail.cargoCutoff && <label>   <b>{"Cargo Cutoff "} : </b> {marinMapDetail.cargoCutoff || "NOT AVAILABLE"}</label>} 
            {marinMapDetail.cargoCutoffComment && <label>   <b>{"Cargo Cutoff Comment "} : </b> {marinMapDetail.cargoCutoffComment || "NOT AVAILABLE"}</label>} 
            {marinMapDetail.reeferCutoff && <label>   <b>{"Reefer Cutoff "} : </b> {marinMapDetail.reeferCutoff || "NOT AVAILABLE"}</label>} 
            {marinMapDetail.reeferCutoffComment && <label>   <b>{"Reefer Cutoff Comment "} : </b> {marinMapDetail.reeferCutoffComment || "NOT AVAILABLE"}</label>} 
            {marinMapDetail.hazmatCutoff && <label>   <b>{"Hazmat Cutoff "} : </b> {marinMapDetail.hazmatCutoff || "NOT AVAILABLE"}</label>} 
            {marinMapDetail.autoCutoff && <label>   <b>{"Hazmat Cutoff Comment "} : </b> {marinMapDetail.hazmatCutoffComment || "NOT AVAILABLE"}</label>} 
            {marinMapDetail.autoCutoff && <label>   <b>{"Auto Cutoff "} : </b> {marinMapDetail.autoCutoff || "NOT AVAILABLE"}</label>} 
            {marinMapDetail.autoCutoffComment && <label>   <b>{"Auto Cutoff Comment "} : </b> {marinMapDetail.autoCutoffComment || "NOT AVAILABLE"}</label>}  
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
