import { getAnalytics, logEvent } from "firebase/analytics";
import platform from "platform";

export const submitGAEvent = (pageName) => {
  const analytics = getAnalytics();
  const OS = platform.name;
  let obj = {};
  obj.DEVICE_NAME = "web";
  obj.DEVICE_OS = OS || "Unknown";
  obj.USER_TYPE = JSON.parse(localStorage.getItem("user_type")) || "Guest";
  obj.DEVICE_UUID = sessionStorage.getItem("uuid") || "-";
  obj.PAGE_CLICK = pageName;
  obj.USER_APP_LANGUAGE = localStorage.getItem("lang") || "en";
  logEvent(analytics, "USER_VIEW_INFO", obj);
};
