import { toast } from "react-toastify";
import { languageJson } from "src/StringVariable";
toast.configure();

/**
 * @description handles success returned from api
 * @param {*} response reponse returned from api
 */
export const HandleSuccess = (response) => {
  let customcode = response.data.customcode;
  if (
    customcode === 201 ||
    customcode === 202 ||
    customcode === 203 ||
    customcode === 204 ||
    customcode === 205 ||
    customcode === 206 ||
    customcode === 207 ||
    customcode === 208 ||
    customcode === 210 ||
    customcode === 500
  ) {
    toast.error(response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  if (response.data.customcode === 401 || response.data.customcode === 402) {
    // dispatch(userLogout(history))
  }
  if (response.data.customcode === 200 && response.data.error === false) {
    toast.success(response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

/**
 * @description handles error returned from api
 * @param {*} error error message returned from api
 */
export const handleError = (error = {}) => {
  const err = typeof error === "string" ? new Error(error) : error;
  if (!err.message) {
    err.message = languageJson.unexpected_error_message_txt;
  }
  return err;
};

/**
 * @description handles success returned from api
 * @param {*} response reponse returned from api
 */
export const NotificationPopup = (customcode, message) => {
  if (
    customcode === 250 ||
    customcode === 422 ||
    customcode === 400 ||
    customcode === 201 ||
    customcode === 202 ||
    customcode === 203 ||
    customcode === 204 ||
    customcode === 205 ||
    customcode === 206 ||
    customcode === 207 ||
    customcode === 208 ||
    customcode === 210 ||
    customcode === 500
  ) {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  if (customcode === 401 || customcode === 402) {
    // dispatch(userLogout(history))
  }
  if (customcode === 200) {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
