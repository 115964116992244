import React, { useEffect } from "react";
import Header from "../../Containers/Header";
import Aboutusbanner from "../../assets/aboutus-banner.png";
import { languageJson } from "../../StringVariable";

function SearchPage() {
  useEffect(() => {
    setTimeout(() => {}, 3000);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="searchSectionMain">
      <Header />
      <div className="headerBannerMainDiv">
        <img className="banner_img" src={Aboutusbanner} alt="" />
        <b>{languageJson.search_txt}</b>
      </div>
      <section className="search-section">
        <div className="container">
          <div className="row ">
            <div className="row postionRelative ">
              <div className="col-lg-3"></div>
              <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 titleTxtDiv center-head-underline d-flex justify-content-center py-4">
                <h1 className="text-center  py-2 f-head"> </h1>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-4 col-xs-4 viewBtnDiv  d-flex justify-content-end  align-items-center"></div>
            </div>
          </div>
          {/* first row */}
          <div className="row">
            <label className="searchHereDiv">
              {languageJson.search_here_txt}
            </label>
            <div className="gcse-search"></div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SearchPage;
