const BASE_URL = process.env.REACT_APP_ENDPOINT;
export const TRUCKROUTE_URL = `${window.location.origin}/TruckRoutes.kml`;
const apiPath = "/api/v1";
export const FAQFILE_MARINE_TERMINAL =
  BASE_URL + "/files/Truck_Turn_Time_FAQs.pdf";
export const TRUCK_ROUTES_VIEW =
  "https://www.arcgis.com/apps/Embed/index.html?webmap=8cd63bb6e50042d994d2e29e8308c950&extent=-122.426,37.7316,-121.9027,37.8935&zoom=false&previewImage=false&scale=false&legendlayers=true&disable_scroll=false&theme=light";

export const SPS_BASE_URL =
  "https://smartparking-web-stage-sandbox.portofoakland.com/#/checkout/";
// export const SPS_BASE_URL="https://smartparkingyystem-api.mobileprogramming.net/checkout/"

export const CCTV_DATA_URL =
  "https://cwwp2.dot.ca.gov/data/d4/cctv/cctvStatusD04.json";
export const CMS_MESSEGE_SIGNS_URL =
  "https://cwwp2.dot.ca.gov/data/d4/cms/cmsStatusD04.json";
export const CHAIN_CONTROL_SIGNS_URL =
  "https://cwwp2.dot.ca.gov/data/d3/cc/ccStatusD03.json";
export const OAKLAND_PORTAL_CAMERA_URL =
  apiPath + "/marine/cameraList?district=all";
export const VESSEL_OPERATIONS_URL = apiPath + "/vessel/getSchedules";
export const REGIONAL_CAMERAS_URL = apiPath + "/quickmap/regionalCamera";
export const MARINE_CAMERAS_LIST_BY_DISTRICT = apiPath + "/marine/cameraList/";
export const MARINE_ALERTS_BY_DISTRICT =
  apiPath + "/alert/getMarineTerminalAlerts";

export const GET_VESSEL_REPORT_URL = apiPath + "/vessel/getVesselReport";
export const GET_MARINE_TERMINAL_URL = apiPath + "/marine/listMarineTerminals";

export const GET_MARINE_TERMINAL_DATA_BY_ID_URL =
  apiPath + "/marine/gateByMarineId/";
// export const GET_HISTORICAL_DATA_API = (aggregate, marineGateId, year, month) =>
//   apiPath +
//   `/quickmap/historicalAggregatedInformation?aggregate=${aggregate}&gate_name=${marineGateId}&year=${year}&month=${month}`;

export const GET_HISTORICAL_DATA_API = (aggregate, terminal_name, fromdate) =>
  apiPath +
  `/quickmap/historicalTruckTurnTime?aggregate=${aggregate}&gate_name=${terminal_name}&fromdate=${fromdate}`;
export const GET_HISTORICAL_FILTER_DATA_API =
  apiPath + `/quickmap/historicalTruckTurnTimeFilter`;

export const NEWS_GET_BY_ID = apiPath + "/webportal/getNewsUpdatebyId/";
export const GET_NEWS_UPDATE = apiPath + "/webportal/getNewsUpdate";
export const GET_RAILWAY_ALERT_LIST = apiPath + "/alert/getrailAlerts";
export const GET_ALERT_ANNOUNCEMENTS = apiPath + "/alert/announcement";

export const GET_RAILWAY_ROUTE_LIST = apiPath + "/railcrossing/railroads";
export const GET_DATA_ABOUT_US_PAGE_URL = apiPath + "/user/getAboutus";
export const DRIVER_REGISTRATION_API = apiPath + "/user/registration";
export const PUBLIC_REGISTRATION_API = apiPath + "/user/publicRegistration";
export const LMC_REGISTRATION_API = apiPath + "/user/lmcRegistration";
// export const DELETE_ACCOUNT_API = apiPath + "/user/deleteAccount";  // Direct delete account of user
export const DELETE_USER_ACCOUNT_API = apiPath + "/user/deleteUserAccount"; // delete account request api
export const CANCEL_DELETE_USER_ACCOUNT_API =
  apiPath + "/user/cancelDeleteAccount"; // cancel delete account request api
export const GET_FAQ_DATA_API = apiPath + "/webportal/getFaq";
export const NEWS_AND_UPDATE_API = apiPath + "/webportal/getNewsUpdate";
export const SAVE_CONTACT_US_API = apiPath + "/user/saveContactus";
export const GET_USER_RESOURCES_DATA_API = apiPath + "/user/getResources";
export const GET_TOLL_LIST_API = apiPath + "/toll/listTolls";
export const GET_SECURITY_AND_SAFETY_API =
  apiPath + "/safetyandsecurity/osowinfo";
export const USER_AUTH_API = apiPath + "/auth/login";
export const GET_VESSEL_LOCATION_API = apiPath + "/vessel/getLocations";
export const GET_LIST_OF_DATA_BY_MARINE_ID = apiPath + "/marine/graph/";
export const GET_TRAFFIC_AND_CAMERA_ALERT =
  apiPath + "/alert/getTrafficandCamerasAlert";
export const GET_ALERT_LIST_FOR_VESSEL_OPERATION =
  apiPath + "/alert/getAllVesselAlerts";

export const GET_ALERT_LIST_FOR_MARINE = apiPath + "/alert/getAllMarineAlerts";
export const STATES_LIST = BASE_URL + "/files/cities.json";
export const WEBSITE_VIDEO = BASE_URL + "/files/banner.mp4";
export const ROAD_CAMERA_LIST_DATA = apiPath + "/roadcamera/listData/";
export const SECURITY_AND_SAFETY_ALERT_API =
  apiPath + "/alert/getSecuritySafetyAlert";
export const DISTRICT_MESSAGE_SIGNS_URL = apiPath + "/quickmap/CmsStatus";

export const OPEN_WEATHER_MAP_API = "http://api.openweathermap.org";

export const GOOGLE_API_KEY = "AIzaSyD-4SBTAXT8VxVdqW2gaEyKRYXXHyhXnmc";
export const WEATHER_API_KEY = "da96eb4baf61d9072b73109f2eab2eb8";

export const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${GOOGLE_API_KEY}`;
//admin api
export const GET_DASHBOARD_DATA_URL = apiPath + "/admin/dashboard/getDashboard";
export const GET_DASHBOARD_DATA_URL1 =
  apiPath + "/admin/dashboard/getDashboard1";
export const GET_DASHBOARD_DATA_URL2 =
  apiPath + "/admin/dashboard/getDashboard2";
export const GET_DASHBOARD_LMC_DATA_URL =
  apiPath + "/admin/dashboard/spsCounts";
export const GET_USER_LIST = (page, userType, limit, searchText) =>
  apiPath +
  `/admin/dashboard/listUsers/${page}/${limit}/${userType}/${searchText}`;
export const GET_LMC_USER_LIST = (page, limit, searchText) =>
  apiPath + `/admin/dashboard/listLMCUsers/${page}/${limit}/${searchText}`;
export const UPDATE_STATUS = apiPath + "/admin/dashboard/updateProfileStatus";
export const DELETE_USER_FROM_DATA_URL = apiPath + "/user/deleteUserByEmail";
export const CREATE_ADMIN_USER_URL = apiPath + "/admin/dashboard/createAdmin";
export const UPDATE_USER_DETAIL = apiPath + "/user/updateProfile";

export const GET_NEWS_AND_UPDATE_URL = (page, limit, searchText) =>
  apiPath + `/admin/news/listNewsUpdate/${page}/${limit}/${searchText}`;
export const CREATE_NEW_NEWS = apiPath + "/admin/news/saveNewsUpdate";
export const DELETE_NEWS_FROM_NEWS_ID =
  apiPath + "/admin/news/deleteNewsUpdate/";
export const UPDATE_NEWS_URL = apiPath + "/admin/news/updateNewsUpdate/";

export const GET_RELEASE_NOTES_URL = (page, limit, searchText) =>
  apiPath + `/admin/news/listReleaseNote/${page}/${limit}/${searchText}`;
export const CREATE_RELEASE_NOTE = apiPath + "/admin/news/saveReleaseNote";
export const DELETE_RELEASE_NOTE_FROM_ID =
  apiPath + "/admin/news/deleteReleaseNote/";
export const UPDATE_RELEASE_NOTE_URL =
  apiPath + "/admin/news/updateReleaseNote/";
export const GET_RELEASE_NOTES_FOR_PUBLIC =
  apiPath + "/webportal/getReleaseVersions";

export const GET_HOLIDAY_URL = (page, limit, searchText) =>
  apiPath + `/admin/news/listHoliday/${page}/${limit}/${searchText}`;
export const CREATE_HOLIDAY = apiPath + "/admin/news/saveHoliday";
export const DELETE_HOLIDAY_FROM_ID = apiPath + "/admin/news/deleteHoliday/";
export const UPDATE_HOLIDAY_URL = apiPath + "/admin/news/updateHoliday/";
// export const GET_HOLIDAYS_FOR_PUBLIC = apiPath + "/webportal/getHolidays/";
export const GET_HOLIDAYS_FOR_PUBLIC = apiPath + "/webportal/getAllHolidays";
export const GET_ALL_MARINE_TERMINAL_LIST =
  apiPath + "/admin/marineTerminal/getAllMarineTerminals";

export const GET_ABOUT_US_LIST = (page, limit, searchText) =>
  apiPath + `/admin/aboutus/listAboutusCategory/${page}/${limit}/${searchText}`;
export const CREATE_ABOUT_US = apiPath + "/admin/aboutus/saveAboutusCategory";
export const DELETE_ABOUT_US_BY_ID =
  apiPath + "/admin/aboutus/deleteAboutusCategory/";
export const UPDATE_ABOUT_US_DETAIL =
  apiPath + "/admin/aboutus/updateAboutusCategory/";

export const GET_ABOUT_US_SUB_CAT_LIST = (page, limit, searchText) =>
  apiPath +
  `/admin/aboutus/listAboutusSubCategory/${page}/${
    limit ? limit : 10
  }/${searchText}`;
export const CREATE_ABOUT_US_SUB_CAT =
  apiPath + "/admin/aboutus/saveAboutusSubCategory";
export const DELETE_ABOUT_US_SUB_CAT =
  apiPath + "/admin/aboutus/deleteAboutusSubCategory/";
export const UPDATE_ABOUT_US_SUB_CAT_DETAIL =
  apiPath + "/admin/aboutus/updateAboutusSubCategory/";

export const GET_RESOURCE_CATEGORY_LIST = (page, limit, searchText) =>
  apiPath +
  `/admin/resource/listResourceCategory/${page}/${limit}/${searchText}`;
export const CREATE_RESOURCE_CATEGORY =
  apiPath + "/admin/resource/saveResourceCategory";
export const UPDATE_RESOURCE_CATEGORY =
  apiPath + "/admin/resource/updateResourceCategory/";
export const DELETE_RESOURCE_CATEGORY =
  apiPath + "/admin/resource/deleteResourceCategory/";

export const GET_RESOURCE_SUB_CATEGORY_LIST = (page, limit, searchText) =>
  apiPath +
  `/admin/resource/listResourceSubCategory/${page}/${limit}/${searchText}`;
export const CREATE_SUB_RESOURCE_CATEGORY =
  apiPath + "/admin/resource/saveResourceSubCategory";
export const UPDATE_SUB_RESOURCE_CATEGORY =
  apiPath + "/admin/resource/updateResourceSubCategory/";
export const DELETE_SUB_RESOURCE_CATEGORY =
  apiPath + "/admin/resource/deleteResourceSubCategory/";

export const GET_MARINE_TERMINAL_LIST = (page, limit, searchText) =>
  apiPath +
  `/admin/marineTerminal/listMarineTerminal/${page}/${limit}/${searchText}`;
export const DELETE_MARINE_TERMINAL_BY_ID =
  apiPath + "/admin/marineTerminal/deleteMarineTerminal/";
export const CREATE_MARINE_TERMINAL =
  apiPath + "/admin/marineTerminal/saveMarineTerminal";
export const UPDATE_MARINE_TERMINAL_DETAIL =
  apiPath + "/admin/marineTerminal/updateMarineTerminal/";
export const GET_ON_TIME_REPORT = apiPath + "/vessel/getOnTimeReport";
export const GET_DISTRICT_LIST =
  apiPath + "/admin/marineTerminal/getAllDistrict";
export const GET_PORT_CAMERA_LIST = (page, limit, searchText) =>
  apiPath + `/admin/marineTerminal/cameraList/${page}/${limit}/${searchText}`;

export const GET_FAQ_LIST = (page, limit, searchText) =>
  apiPath + `/admin/faq/listFaq/${page}/${limit}/${searchText}`;
export const DELETE_FAQ_BY_ID = apiPath + "/admin/faq/deleteFaq/";
export const CREATE_FAQ = apiPath + "/admin/faq/saveFaq";
export const UPDATE_FAQ = apiPath + "/admin/faq/updateFaq/";

export const GET_URL_LIST = (page, limit, searchText) =>
  apiPath + `/admin/faq/listUrl/${page}/${limit}/${searchText}`;
export const DELETE_URL_BY_ID = apiPath + "/admin/faq/deleteUrl";
export const CREATE_URL = apiPath + "/admin/faq/saveUrl";
export const UPDATE_URL = apiPath + "/admin/faq/updateURl/";
export const PUBLIC_URL_API = apiPath + "/truck/singleUrls";
export const GET_LOGIN_OTP = apiPath + "/auth/getLoginOtp";
export const VERIFY_OTP = apiPath + "/user/verifyOtp";
export const FORGOT_PASSWORD = apiPath + "/auth/forgotPassword";
export const RESET_PASSWORD = apiPath + "/auth/resetPassword";
export const USER_DETAIL_API = apiPath + "/user/viewProfile";
export const USER_SETTING_API = apiPath + "/user/getSetting";
export const UPDATE_USER_SETTING_API = apiPath + "/user/userSetting";
//Parking api
export const GET_PARKING_LIST = (spsid) =>
  apiPath + `/quickmap/booking/${spsid}`;
export const GET_ACTIVITY_PARKING_LIST =
  apiPath + "/quickmap/activityForAccount";
export const GET_INVENTORY_PARKING_LIST =
  apiPath + "/quickmap/inventoryForAccount";
export const SEARCH_PARKING_ZONE_URL = apiPath + "/quickmap/searchZone";
export const CANCEL_BOOKING_BY_ID = apiPath + "/quickmap/cancelOneTimeBooking/";
export const CANCEL_BOOKING_BY_SUBSCRIPTION_ID =
  apiPath + "/quickmap/cancelBookingSubscription/";
export const BOOKING_RESERVATION_API = apiPath + "/quickmap/bookingReservation";
export const GET_WAITING_PARKING_LIST = apiPath + `/quickmap/waitListEntries`;
export const CANCEL_WAITING_BOOKING_BY_ID =
  apiPath + "/quickmap/removeWaitListEntry/";
export const GET_LOACTION_BY_LOCATION_ID = apiPath + "/quickmap/location/";
export const GET_PARKING_PAYMENT_HISTORY =
  apiPath + "/quickmap/invoicesForAccount";
export const GET_LIST_OF_LOCATION = apiPath + "/quickmap/location";
export const GET_LIST_OF_BOOKED_SPACE_SEARCH =
  apiPath + "/quickmap/bookedSpaceCalendar";
export const GET_LIST_OF_ACTIVITY_SUMMARY =
  apiPath + "/quickmap/activitySummaryReport";

export const BOOKING_CREATE_WAITING_LIST_API =
  apiPath + "/quickmap/createWaitListEntry/";
export const BOOKING_CONFIRM_API = apiPath + "/quickmap/completeReservation/";

export const CHANGE_PASSWORD_API = apiPath + "/auth/updatePassword";

export const GET_TOLL_LIST = (page, limit, searchText) =>
  apiPath + `/admin/toll/listToll/${page}/${limit}/${searchText}`;
export const DELETE_TOLL_BY_ID = apiPath + "/admin/toll/deleteToll/";
export const CREATE_TOLL = apiPath + "/admin/toll/saveToll";
export const UPDATE_TOLL = apiPath + "/admin/toll/updateToll/";

export const GET_FEEDBACK_LIST = (page, limit, searchText) =>
  apiPath + `/admin/contact/listContact/${page}/${limit}/${searchText}`;
export const DELETE_FEEDBACK_BY_ID = apiPath + "/admin/contact/deleteContact/";
export const UPDATE_FEEDBACK = apiPath + "/admin/contact/updateContact/";
export const FEEDBACK_EMAIL_REPLY = apiPath + "/admin/contact/emailReply";

export const UPDATE_LMC_USER = apiPath + "/admin/dashboard/editLmcUser";
export const CREATE_LMC_USER = apiPath + "/admin/dashboard/createLmcUser";
export const LMC_USER_PROFILE_UPDATE = apiPath + "/user/updateLmcProfile";

export const CREATE_USER = apiPath + "/admin/dashboard/createUser";
export const USER_UPLOAD_PROFILE_PIC = apiPath + "/user/updateProfilePic";
export const UPDATE_PROT_ADN_DRIVER_USER =
  apiPath + "/admin/dashboard/editUser/";

export const UPDATE_ADMIN_USER = apiPath + "/admin/dashboard/editAdmin/";

export const GET_OSOW_LIST = (page, limit, searchText) =>
  apiPath + `/admin/osow/list/${page}/${limit}/${searchText}`;
export const CREATE_NEW_OSOW = apiPath + "/admin/osow/save";
export const UPDATE_OSOW = apiPath + "/admin/osow/update/";
export const DELETE_OSOW_BY_ID = apiPath + "/admin/osow/deleteOsow/";

export const GET_ALERT_LIST = (page, limit, searchText) =>
  apiPath + `/admin/alert/listAlert/${page}/${limit}/${searchText}`;
export const CREATE_ALERT = apiPath + "/admin/alert/saveAlert";
export const DELETE_ALERT_BY_ID = apiPath + "/admin/alert/deleteAlert/";
export const UPDATE_ALERT_DETAIL = apiPath + "/admin/alert/updateAlert/";
export const RESEND_ALERT_NOTIFICATION_API =
  apiPath + "/admin/alert/resendAlertNotifcation/";

export const GET_ALERT_CAT_LIST = (page, limit, searchText) =>
  apiPath +
  `/admin/alert/listAlertSectionCategory/${page}/${limit}/${searchText}`;
export const CREATE_ALERT_CAT =
  apiPath + "/admin/alert/saveAlertSectionCategory";
export const DELETE_ALERT_CAT_BY_ID =
  apiPath + "/admin/alert/deleteAlertSectionCategory/";
export const UPDATE_ALERT_CAT_DETAIL =
  apiPath + "/admin/alert/updateAlertSectionCategory/";

export const GET_ALERT_SUB_CAT_LIST = (page, limit, searchText) =>
  apiPath +
  `/admin/alert/listAlertSectionSubCategory/${page}/${limit}/${searchText}`;
export const CREATE_ALERT_SUB_CAT =
  apiPath + "/admin/alert/saveAlertSectionSubCategory";
export const DELETE_ALERT_SUB_CAT =
  apiPath + "/admin/alert/deleteAlertSectionSubCategory/";
export const UPDATE_ALERT_SUB_CAT_DETAIL =
  apiPath + "/admin/alert/updateAlertSectionSubCategory/";
export const GET_ALL_SUB_CAT_LIST = apiPath + "/admin/alert/allSubCategory/";

// driver Managment api

export const GET_DRIVER_USER_LIST = apiPath + "/quickmap/drivers/";
export const CREATE_DRIVER_BY_LMC_USER = apiPath + "/quickmap/createDriver";
export const UPDATE_DRIVER_BY_LMC_USER = apiPath + "/quickmap/updateDriver/";
export const UPDATE_STATUS_TO_REVOKE = apiPath + "/quickmap/revokeDriver/";
export const UPDATE_STATUS_TO_ACTIVE = apiPath + "/quickmap/activateDriver/";
export const GET_DRIVER_IMAGE_URL = apiPath + "/quickmap/driverHeadshot/";
export const DRIVER_USER_UPLOAD_PROFILE_PIC =
  apiPath + "/quickmap/uploadDriverPhoto";

export const GET_PARKING_ALERT_LIST = (spsid) =>
  `${apiPath}/quickmap/getParkingAlerts/${spsid}`;
export const GET_DRIVER_MANAGMENT_ALERT_LIST = (spsid) =>
  `${apiPath}/quickmap/getDriverAlerts/${spsid}`;

// User Reports
export const GET_USER_REPORTS_DATE_API = (startDate, endDate) =>
  apiPath +
  `/admin/dashboard/reportAnalytics?startDate=${startDate}&endDate=${endDate}`;
// Guest User Api
export const GET_GUEST_USER_ID = apiPath + "/auth/guestLogin";

export const VESSEL_SCHEDULES_ADVENT = (terminalName) =>
  apiPath + `/vessel/getAdventSchedules/${terminalName}`;

export const GET_SECURITY_DEPOSIT_LIST = (spsid) =>
  apiPath + `/quickmap/getSecurityDeposit/${spsid}`;
export const SEND_SECURITY_DEPOSIT_REQUEST =
  apiPath + "/quickmap/sendRequestForSecurityDeposit";

export const GET_ERI_API = apiPath + "/datahub/shippingline";

// Logistcis APIs
export const GET_LOGISTICS_MAP_LIST = (page, limit, searchText) =>
  apiPath + `/admin/logisticsMap/list/${page}/${limit}/${searchText}`;
export const CREATE_LOGISTICS_MAP = apiPath + "/admin/logisticsMap/save";
export const DELETE_LOGISTICS_MAP_BY_ID =
  apiPath + "/admin/logisticsMap/delete/";
export const UPDATE_LOGISTICS_MAP_DETAIL =
  apiPath + "/admin/logisticsMap/update/";
export const GET_PUBLIC_LOGISTICS_MAP_LIST = apiPath + `/quickmap/logisticsMap`;
export const GET_DATAHUB_ERI_LIST = apiPath + `/quickmap/eri`;
