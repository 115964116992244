import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { languageJson } from "src/StringVariable";

export default function SearchComponent({
  onClickSearch,
  onChange,
  type,
  value,
  placeholder,
}) {
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[A-Za-z0-9 ]*$/; // Allows letters, numbers, and spaces only
    if (regex.test(inputValue)) {
      onChange(e); // Call the parent onChange if input is valid
    }
  };

  return (
    <div className="reportSectionMainDiv">
      <div className="searchFilterDiv">
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange} // Use the new onChange handler here
        ></input>
        <div className="serachOption">
          <button
            onClick={() => onClickSearch(type)}
            disabled={value.length > 1 ? false : true}
          >
            <i className="fa fa-search" aria-hidden="true"></i>
            {languageJson.search_txt}
          </button>
        </div>
      </div>
    </div>
  );
}

// PropTypes for validating the expected prop types
SearchComponent.propTypes = {
  onClickSearch: PropTypes.func.isRequired, // Function expected for onClickSearch
  onChange: PropTypes.func.isRequired, // Function expected for onChange
  type: PropTypes.string.isRequired, // String expected for type
  value: PropTypes.string.isRequired, // String expected for value
  placeholder: PropTypes.string.isRequired, // String expected for placeholder
};
