import React from "react";
import "./LogisticsMapViewLegend.css";
import customMarkerImage1 from "../../assets/legend/1.png";
import customMarkerImage2 from "../../assets/legend/2.png";
import customMarkerImage3 from "../../assets/legend/3.png";
import customMarkerImage4 from "../../assets/legend/4.png";
import customMarkerImage5 from "../../assets/legend/5.png";
import customMarkerImage6 from "../../assets/legend/6.png";
import { languageJson } from "../../StringVariable";
const {
  facility_type,
  facility_type_1,
  facility_type_2,
  facility_type_3,
  facility_type_4,
  facility_type_5,
  facility_type_6,
} = languageJson.LogisticsMap;
const LogisticsMapViewLegend = (props) => {
  const facilities = [
    {
      idName: "Warehouse",
      name: facility_type_1,
      imgSrc: customMarkerImage3,
    },
    {
      idName: "Distribution Center",
      name: facility_type_2,
      imgSrc: customMarkerImage2,
    },
    {
      idName: "Warehouse-Distribution Center",
      name: facility_type_3,
      imgSrc: customMarkerImage1,
    },
    {
      idName: "Freight Forwarders",
      name: facility_type_4,
      imgSrc: customMarkerImage4,
    },
    {
      idName: "Preferred Partners",
      name: facility_type_5,
      imgSrc: customMarkerImage5,
    },
    {
      idName: "Other",
      name: facility_type_6,
      imgSrc: customMarkerImage6,
    },
  ];

  

  return (
    <div>
      {/* <h6 style={{ margin: "15px" }}>{facility_type}</h6> */}
      <div className="container-legend">
        {facilities.map((facility, index) => (
          <div className="facility-legend checkbox" key={index}  onClick={() => {
            props.handleWindowClick(null);
          }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',width: '100%' }}>    
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <img src={facility.imgSrc} alt={facility.name} />
                <div className="facility-legend-name">{facility.name}</div>
                </div>           
                <input
                style={{ marginLeft: 'auto', marginRight:'30px'}}
                  key={index}
                  type="checkbox"
                  id={facility.idName}
                  checked={props.activeList.includes(facility.idName) ? true : false}
                  onChange={() => props.toggleChange(facility.idName)}
                />
                <i></i>       
           
          </div>
        ))}
        <button onClick={() => props.selectAll()}>
            {" "}
            {props.activeList.length < 6
              ? languageJson.select_all_txt
              : languageJson.deselect_all_txt}
          </button>
      </div>
    </div>
  );
};

export default LogisticsMapViewLegend;
