import React, { useState, useEffect } from "react";
//  import everport from "../../assets/port-logo-1.png";
// import trapac from "../../assets/port-logo-2.png";
// import matson from "../../assets/port-logo-3.png";
// import ssa from "../../assets/port-logo-4.png";
import oaklandlogo from "src/assets/new_logo.png";
import NoImage from "src/assets/no-image.png";
 import moment from "moment";
import "./historical.css";
 import { GET_MARINE_TERMINAL_URL, GET_HISTORICAL_FILTER_DATA_API } from "../../url/Url";
import axios from "src/redux/actions/apis";
import { languageJson } from "../../StringVariable";
import DataNotFound from "../DataNotFound/DataNotFound";
import SpinerLoader from "src/utils/SpinerLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NotificationPopup } from "src/utils/Notifications";
import { submitGAEvent } from "src/utils/GAEvents";
import { isAfter,isEqual } from 'date-fns';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
moment.suppressDeprecationWarnings = true;

function HistoricalTruckTurnTimeHead() {
  const [allMarineTerminals, setAllMarineTerminals] = useState(null);
  const [gate_name, setMarineGateName] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [terminalsData, setTerminalsData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [fromValue, onChangeFromValue] = useState('07:00');
  const [toValue, onChangeToValue] = useState('17:00');
  const [isError, setIsError] = useState(false);
  // const disabledHours = () => {return [1, 2, 3, 4]};
  const onFromValueChange = (value) => {
    let fromInputValue = value && value.format('hh:mm') ? value.format('HH:mm') : null
    onChangeFromValue(fromInputValue)
  }

  const onToValueChange = (value) => {
    let toInputValue = value && value.format('hh:mm') ? value.format('HH:mm') : null
    onChangeToValue(toInputValue)
  }

  useEffect(() => {
    submitGAEvent("HISTORICAL TRUCK TURN TIMES");
  });

  const getMarineDataList = async () => {
    const requestObj = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios
      .get(GET_MARINE_TERMINAL_URL, requestObj)
      .then((res) => {
        const { marineTerminals, customcode } = res.data;
        if (customcode === 200) {
          setAllMarineTerminals(marineTerminals);
        } else {
          setAllMarineTerminals([]);
        }
      })
      .catch(function (error) {
        NotificationPopup(403, error.message);
      });
  };

  const getTime = () => {
    let time = new Date();
    if (moment(time).format("h:mm A") !== "Invalid date") {
      return moment(time).format("L h:mm A");
    } else {
      return <b>{"-"}</b>;
    }
  };

  const onchangeDate = (date, type) => {
    if(type==="from"){
      setFromDate(date);
    }if(type==="to"){
      setToDate(date);
    }
  };

  const onSearchClick = async () => {
    setIsError(false)
    setLoader(true);
    let fromDateFormat=fromDate.toISOString().slice(0, 10);
    const arrFromDate = fromDateFormat.split('-');
    const finalFromDate = arrFromDate[0]+'-'+arrFromDate[1]+'-'+arrFromDate[2];
    let toDateFormat= toDate.toISOString().slice(0, 10);
    const arrtoDate = toDateFormat.split('-');
    const finalToDate = arrtoDate[0]+'-'+arrtoDate[1]+'-'+arrtoDate[2];
    const date1 = new Date(finalFromDate);
    const date2 = new Date(finalToDate);
    if (isAfter(date1, date2)) {
     setLoader(false); 
     setIsError(true)
     return
    }
    if (isEqual(date1, date2) && fromValue>=toValue) {
      setLoader(false); 
      setIsError(true)
      return
     }
    let gateName
    if(gate_name === ""){
     gateName="All"
   }else{
     gateName=gate_name
   }
    let obj = {
      gateName : gateName,
      fromDate : finalFromDate,
      toDate : finalToDate,
      fromTime : fromValue ? fromValue : '07:00',
      toTime : toValue ? toValue : '17:00',
    }
    setTerminalsData(null);
    await axios
      .post(GET_HISTORICAL_FILTER_DATA_API, obj)
      .then((res) => {
        const { customcode, data } = res.data;
        if (customcode === 200) {
          setTerminalsData(data);
          setLoader(false);
        } else {
          setTerminalsData([]);
        }
        setLoader(false);
      })
      .catch(function (error) {
        NotificationPopup(403, error.message);
      });
  };

  const getTerminalTrunTime = (time) => {
    if (time < 60) {
      return `terminal__turn__time__green`;
    } else if (time >= 60 && time <= 90) {
      return `terminal__turn__time__yellow`;
    } else {
      return `terminal__turn__time__red`;
    }
  };


  const getStreetWaitTime = (time) => {
    if (time < 15) {
      return `street__wait__time__green`;
    } else if (time >= 15 && time <= 30) {
      return `street__wait__time__yellow`;
    } else {
      return `street__wait__time__red`;
    }
  };


  // const selectLogo = (value) => {
  //   switch (value) {
  //     case "matson":
  //       return matson;
  //     case "everport":
  //       return everport;
  //     case "trapac":
  //       return trapac;
  //     case "oict":
  //       return ssa;
  //     default:
  //       return oaklandlogo;
  //   }
  // };
  const getTerminalCurrentWaitTime = (time) => {
    time = parseInt(time);
    var hours = Math.floor(time / 60);
    var minutes = time % 60;
    if (minutes.toString().length === 1) {
      minutes = minutes.toString().replace(minutes, `0${minutes}`);
    }

    if (hours === 1 && minutes > 1) {
      return `${hours} ${languageJson.hour_txt}   ${minutes} ${languageJson.minutes_txt}`;
    }
    if (hours === 0 && minutes > 1) {
      return `${minutes} ${languageJson.minutes_txt}`;
    }
    if (hours === 0 && minutes === 1) {
      return `${minutes} ${languageJson.minutes_txt}`;
    }
    if (minutes === 0) {
      if (hours > 1) {
        return `${hours} ${languageJson.hours_txt}`;
      } else {
        return `${hours} ${languageJson.hour_txt}`;
      }
    } else {
      return `${hours} ${languageJson.hours_txt}  ${minutes} ${languageJson.minutes_txt}`;
    }
  };

  useEffect(() => {
    getMarineDataList();
    getTime();
  }, []);

  const handleKeyPress = (e) => {
    e.preventDefault();
  };

  // const convertMetersToMiles = (value) => {
  //   const df = value * 0.00062137;
  //   return parseFloat(df).toFixed(2);
  // };


  const DatePickerCustomInput = () => (
    <div className="calendar_icon newCalendarIcon">
      <i className="fa fa-calendar" aria-hidden="true"></i>
    </div>
  );
  return (
    <div>
      <div className="truckTurnTimeMainDiv historicalTTTMainDiv">
        <div className="container">
          <div className="col-12 lastUpdateDiv">
            {isError && <div className="col-12 lastUpdateTitleDiv alert alert-danger" role="alert">
            {languageJson.Historical_Truck_Filter.validation_txt}
            </div> }
            <div className="col-12 listOptionDiv">
              <div className="row">
              <div className="col-md-6">
                  {" "}
                  <label>{languageJson.select_gate_txt}</label>
                  <select
                   selected={"All"}
                  onChange={(e)=>setMarineGateName(e.target.value)}              
                    className="browser-default custom-select"
                  >
                    <option value="">{languageJson.all_txt}</option>
                    {allMarineTerminals != null &&
                      allMarineTerminals &&
                      allMarineTerminals.map(
                        (terminal1) =>
                          terminal1.gates.length > 0 &&
                          terminal1.gates.map((terminal, index) => {
                            return (
                              <option value={terminal.gate_name} key={index}>
                                {terminal.gate_name}{" "}
                              </option>
                            );
                          })
                      )}
                  </select>
                </div>
                <div className="col-md-6">
                  {" "}
                  <div className="DatePickerCustomDiv">
                    <label>{languageJson.Historical_Truck_Filter.from_dt}</label>
                    <div className="input-group mb-3">
                      <DatePicker
                        selected={fromDate}
                        onChange={(date) => onchangeDate(date,'from')}
                        selectsStart
                        onKeyDown={handleKeyPress}
                        maxDate={new Date()}
                      />
                      <DatePickerCustomInput />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  {" "}
                  <div className="DatePickerCustomDiv">
                    <label>{languageJson.Historical_Truck_Filter.to_dt}</label>
                    <div className="input-group mb-3">
                      <DatePicker
                        selected={toDate}
                        onChange={(date) => onchangeDate(date,'to')}
                        selectsStart
                        onKeyDown={handleKeyPress}
                        maxDate={new Date()}
                      />
                      <DatePickerCustomInput />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                 <label>{languageJson.Historical_Truck_Filter.from_time}</label>
                    <TimePicker 
                    showSecond = {false}
                    showMinute = {false}
                    defaultValue={moment().hour(7).minute(0)}
                    format={'hh:mm A'}
                    onChange={onFromValueChange}
                    use12Hours
                    className={'timePickerClass'}
                    // hideDisabledOptions={true}
                    // disabledHours={disabledHours}
                    />
                </div>
                <div className="col-md-6">
                  {" "}
                  <div className="DatePickerCustomDiv">
                     <label>{languageJson.Historical_Truck_Filter.to_time}</label>
                    <div className="input-group mb-3">
                    <TimePicker 
                    showSecond = {false}
                    showMinute = {false}
                    defaultValue={moment().hour(17).minute(0)}
                    format={'hh:mm A'}
                    onChange={onToValueChange}
                    use12Hours
                    className={'timePickerClass'}
                    />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <button className="btn btn-primary" onClick={onSearchClick}>
                    <i className="fa fa-search" aria-hidden="true"></i>{" "}
                    {languageJson.search_txt}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="truckTurnTimeListOpt">
            {terminalsData != null && terminalsData.length > 0 ? (
              <div className="historicalSearchList">
                {terminalsData.map((terminal, index) => {
                  return (
                    <div className="card" key={index}>
                      <div className="row">
                        <div className="leftImgMainDiv">
                          <div className="col-md-2 infoListDiv imgLogoDiv">
                            <img
                              className="img-fluid m-2"
                              alt="logo"
                              src={
                                (terminal.terminal_logo !== null &&
                                  terminal.terminal_logo !== " " &&
                                  terminal.terminal_logo !== "" && terminal.terminal_logo !==
                                  "null") ? terminal.terminal_logo
                                  : NoImage
                              }
                              onError={(e) => {
                                e.currentTarget.src = oaklandlogo;
                              }}
                            />{" "}
                          </div>
                        </div>
                        <div className="rightContentMainDiv">
                          <div className="col-md-3 infoListDiv">
                            <span>
                              {" "}
                              {terminal.gate_name}
                            </span>
                            <label> {languageJson.terminal_gate_txt}</label>
                          </div>
                          <div className="col-md-3 infoListDiv">
                            <span
                              style={{ textTransform: "capitalize" }}
                              className={`marinesty ${getTerminalTrunTime(
                                terminal.avg_terminal_turn_time !== null &&
                                  terminal.avg_terminal_turn_time !== ""
                                  ? terminal.avg_terminal_turn_time
                                  : 0
                              )}`}
                            >
                              {terminal.avg_terminal_turn_time !== null &&
                                terminal.avg_terminal_turn_time !== ""
                                ? getTerminalCurrentWaitTime(
                                  terminal.avg_terminal_turn_time
                                )
                                : 0 + languageJson.minute_txt}
                            </span>
                            <label>
                              {languageJson.avg_txt}{" "}
                              {languageJson.Terminal_Turn_Time_txt}{" "}
                            </label>
                          </div>
                          <div className="col-md-3 infoListDiv">
                            <span
                              style={{ textTransform: "capitalize" }}
                              className={`marinesty ${getStreetWaitTime(
                                terminal.avg_street_wait_time !== null &&
                                  terminal.avg_street_wait_time !== ""
                                  ? terminal.avg_street_wait_time
                                  : 0
                              )}`}
                            >
                              {terminal.avg_street_wait_time !== null &&
                                terminal.avg_street_wait_time !== ""
                                ? getTerminalCurrentWaitTime(
                                  terminal.avg_street_wait_time
                                )
                                : 0 + " " + languageJson.minute_txt}
                            </span>
                            <label>
                              {languageJson.avg_txt}{" "}
                              {languageJson.Street_Wait_Time_txt}{" "}
                            </label>
                          </div>
                          <div className="col-md-3 infoListDiv">
                            <span>
                              {" "}
                              {terminal.total_volume !== null &&
                                terminal.total_volume !== ""
                                ? terminal.total_volume
                                : 0}
                            </span>
                            <label>
                              {languageJson.trucks_txt} {languageJson.count_txt}{" "}
                            </label>
                          </div>
                          {/* <div className="col-md-3 infoListDiv">
                            <span>
                              {" "}
                              {convertMetersToMiles(
                                terminal.avg_avg_queue_length !== null &&
                                  terminal.avg_avg_queue_length !== ""
                                  ? terminal.avg_avg_queue_length
                                  : 0
                              )}{" "}
                              {languageJson.miles_txt}
                            </span>
                            <label>{languageJson.avg_queue_length} </label>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : terminalsData !== null && terminalsData.length === 0 ? (
              <DataNotFound />
            ) : (
              loader && <SpinerLoader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoricalTruckTurnTimeHead;
