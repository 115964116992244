import en from "./assets/json/en.json";
import sp from "./assets/json/sp.json";
import pun from "./assets/json/pun.json";

let lang = localStorage.getItem("lang") || "en";
let englishLang = en;
let languageJson = {};

switch (lang) {
  case "en":
    languageJson = en;
    break;
  case "es":
    languageJson = sp;
    break;
  case "pa":
    languageJson = pun;
    break;
  default:
    languageJson = en;
    break;
}

export { languageJson, englishLang };
