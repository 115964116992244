import React, { useState, useEffect } from "react";
import Header from "../../Containers/Header";
import { Link } from "react-router-dom";
import { GET_PUBLIC_LOGISTICS_MAP_LIST, GOOGLE_MAP_URL } from "src/url/Url";
import api from "src/redux/actions/apis";

import "./LogisticsMapView.css";
import portimg1 from "../../assets/RailCrossing/1.jpg";
import portimg2 from "../../assets/RailCrossing/2.jpg";
import portimg3 from "../../assets/RailCrossing/3.jpg";
import { languageJson } from "../../StringVariable";
import SearchComponent from "./SearchComponent";
import LogisticsMapView from "./LogisticsMapView";
import MapWithMarkers from "./MapWithMarkers";
import DataNotFound from "src/Components/DataNotFound/DataNotFound";
import SpinerLoader from "src/utils/SpinerLoader";
import menuLeftIcon from "../../assets/menuLeftBtnBg.png";
import menuLeftIconArrow from "../../assets/menuLeftBtnIcon.png";
import LogisticsMapViewLegend from "./LogisticsMapViewLegend";
import LogisticsMapViewGrid from "./LogisticsMapViewGrid";
const {
  business_phone_number_text,
  facility_type,
  point_of_contact_text,
  additional_features_text,
  description_text,
  facility_phone_number,
  facility_email_id,
  logistics_legend,
} = languageJson.LogisticsMap;

const LogisticsMapViewMain = () => {
  const [data, setData] = useState([]); // Original data (to backup)
  const [mapData, setMapData] = useState([]); // Data for map
  const [filteredData, setFilteredData] = useState([]); // Filtered data based on search
  const [marineListCopy, setMarineListCopy] = useState([]); // Backup of original data for search
  const [loading, setLoading] = useState(true); // Loading state
  const [searchValue, setSearchValue] = useState(""); // Search value
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [newClass, setNewClass] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [isMapView, setIsMapView] = useState(true);

  const [pageSize, setPageSize] = useState(null);
  const initialLat = 37.8337351;
  const initialLng = -122.2379737;
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedMapMarker, setSelectedMapMarker] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: initialLat,
    lng: initialLng,
  }); // Initial map center

  const linkStyle = { color: "#0066cc", textDecoration: "none" };

  // Helper function to handle the website URL
  const handleWebsiteURL = (url) => {
    return url.startsWith("http://") || url.startsWith("https://")
      ? url
      : `https://${url}`;
  };
  // Fetch logistics map data from the API
  const getLogisticsMapDataList = async () => {
    setLoading(true);
    try {
      const response = await api.get(GET_PUBLIC_LOGISTICS_MAP_LIST);
      const { customcode } = response.data;
      if (customcode === 200) {
        const fetchedData = response.data.data;
        setData(fetchedData);
        setMapData(fetchedData);
        setFilteredData(fetchedData); // Initially show all data
        setMarineListCopy(fetchedData); // Backup for search
      } else {
        setData([]);
        setMapData([]);
        setFilteredData([]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Use Effect Hook to fetch data once the component mounts
  useEffect(() => {
    getLogisticsMapDataList();
  }, []);
  useEffect(() => {
    // Update pageSize whenever isMapView changes
    setPageSize(!isMapView ? 15 : 4);
    setCurrentPage(1); // Reset to the first page when the page size changes
  }, [isMapView]); // Dependency array ensures this runs when isMapView changes

  const handleCardClick = (item) => {
    setSelectedCard(item);
    // Update map center when marker is clicked
    setMapCenter({ lat: item.latitide, lng: item.longitide });
  };
  const toggleView = () => {
    setIsMapView((prevState) => !prevState);
  };

  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        setSelectedItem(null);
      }
    };
    window.addEventListener("keydown", listener);
    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [selectedItem]);
  useEffect(() => {
    if (selectedCard) {
      document.body.classList.add("no-scroll-view");
    }
  }, [selectedCard]);
  const closeDetailCard = () => {
    setSelectedCard(null);
    document.body.classList.remove("no-scroll-view");
  };

  const setNewClick = () => {
    setNewClass(!newClass);
    setSelectedMapMarker(null);
  };

  // Search functionality
  const onClickSearch = () => {
    let value = searchValue.toLowerCase();
    // Filter data based on search input
    const filtered = marineListCopy.filter((item) =>
      item["facility_name"].toLowerCase().includes(value)
    );
    setFilteredData(filtered);
    setCurrentPage(1); // Reset pagination to the first page after search
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length < 1) {
      setFilteredData(marineListCopy); // Reset to original data if search input is cleared
      setCurrentPage(1); // Reset pagination when clearing the search
    }
  };

  // Pagination Logic

  const totalPages = Math.ceil(filteredData.length / pageSize);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Checkbox functions
  const [activeList, setActiveList] = useState([
    "Warehouse",
    "Distribution Center",
    "Warehouse-Distribution Center",
    "Freight Forwarders",
    "Preferred Partners",
    "Other",
  ]);
  const [dummyState, rerender] = useState(1);
  const facilities = [
    {
      idName: "Warehouse",
    },
    {
      idName: "Distribution Center",
    },
    {
      idName: "Warehouse-Distribution Center",
    },
    {
      idName: "Freight Forwarders",
    },
    {
      idName: "Preferred Partners",
    },
    {
      idName: "Other",
    },
  ];
  useEffect(() => {}, [dummyState, activeList.length]);

  const onClick = () => {
    rerender(dummyState + 1);
  };

  const toggleChange = (item) => {
    onClick();
    let list = [...activeList];
    if (list.includes(item)) {
      const index = list.indexOf(item);
      list.splice(index, 1);
    } else {
      list.push(item);
    }
    console.log("list", list);
    setActiveList([...list]);
  };

  const selectAll = () => {
    onClick();
    let list = [...activeList];
    if (list.length < 6) {
      facilities.map((item) => {
        if (!list.includes(item.idName)) {
          list.push(item.idName);
        }
        return item;
      });
    } else {
      list = [];
    }
    setActiveList([...list]);
    onClick();
  };

  return (
    <div>
      <Header />
      <div className="image-row-banner">
        <img className="banner_img" src={portimg1} alt="" />
        <img className="banner_img" src={portimg3} alt="" />
        <img className="banner_img" src={portimg2} alt="" />
      </div>
      <div className="vo-bg">
        <div className="container-fluid row py-4 ">
          <div>
            <Link to="/">
              <span className="vo-menu pe-2">{languageJson.home_txt}</span>
            </Link>
            <span className="text-dark ps-2">
              {" "}
              / &nbsp; {languageJson.LogisticsMap.logistics_map_title}
            </span>
          </div>
        </div>
      </div>

      <div
        className="railCrossinglRoutesMainDiv"
        style={{ marginTop: "-100px" }}
      >
        <section className="railcrossing-head container py-5">
          <div className="row container-fluid py-5 tabVesselDiv">
            <div className="d-flex justify-content-center">
              <div className="truckTurnTimeMainDiv historicalTTTMainDiv">
                <div className="container">
                  <div className="titleDescpDiv">
                    <h2>{languageJson.LogisticsMap.logistics_map_title_txt}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {data.length > 0 ? (
            <div className="card-container-value-grid">
              <div className="container-value-grid">
                {/*     justify-content: flex-end; */}
                {/* {!isMapView ? ( */}
                <SearchComponent
                  placeholder={languageJson.LogisticsMap.facility_search_hint}
                  onChange={handleChange}
                  value={searchValue}
                  type={"logistics_data"}
                  onClickSearch={onClickSearch}
                />
                {/* ) : (
                  <div></div>
                )} */}
                <button className="btn btn-primary" onClick={toggleView}>
                  {isMapView
                    ? languageJson.LogisticsMap.switch_to_list
                    : languageJson.LogisticsMap.switch_to_map}
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
          {isMapView ? (
            <div className="logistics-map-divide">
              {/* Second div with List */}
              {loading ? (
                <SpinerLoader />
              ) : data.length ? (
                <>
                  <div className="responsive-list">
                    {/* <div className="card-container-value">
                    <div className="container-value">
                      <SearchComponent
                        placeholder={
                          languageJson.LogisticsMap.facility_search_hint
                        }
                        onChange={handleChange}
                        value={searchValue}
                        type={"logistics_data"}
                        onClickSearch={onClickSearch}
                      />
                    </div>
                  </div> */}

                    {loading ? (
                      <SpinerLoader />
                    ) : filteredData.length === 0 ? (
                      <DataNotFound />
                    ) : (
                      <div className="card-container-logistics">
                        {paginatedData.map((item, index) => (
                          <LogisticsMapView
                            key={index}
                            {...item}
                            onClick={() => handleCardClick(item)}
                          />
                        ))}
                      </div>
                    )}

                    {filteredData.length > pageSize && (
                      <div className="pagination-controls">
                        <button
                          className="btn btn-primary"
                          onClick={prevPage}
                          disabled={currentPage === 1}
                        >
                          {languageJson.LogisticsMap.preview_txt}
                        </button>
                        <span>
                          {" "}
                          Page {currentPage} of {totalPages}{" "}
                        </span>
                        <button
                          className="btn btn-primary"
                          onClick={nextPage}
                          disabled={currentPage === totalPages}
                        >
                          {languageJson.LogisticsMap.next_txt}
                        </button>
                      </div>
                    )}
                  </div>

                  {/* First div with Map */}
                  <div className="responsive-map">
                    {loading ? (
                      <SpinerLoader />
                    ) : data.length === 0 ? (
                      <DataNotFound />
                    ) : (
                      <>
                        <div
                          style={{
                            // height: "75vh",
                            position: "relative",
                          }}
                        >
                          <div
                            id="drawer"
                            style={{ right: "-15px" }}
                            className={`filterMapMainDiv  ${
                              newClass ? "openDiv" : ""
                            }`}
                          >
                            <button
                              onClick={() => {
                                setNewClick();
                              }}
                              id="drawer-handle"
                              className="filterBtn"
                            >
                              <img
                                src={menuLeftIconArrow}
                                alt="icon"
                                className="imgBtnIcon"
                              ></img>
                              <img src={menuLeftIcon} alt="icon"></img>
                            </button>
                            <div
                              id="drawer-content"
                              className="filterOptDiv logistics-drawer"
                            >
                              <h4
                                style={{
                                  marginTop: "15px",
                                  marginLeft: "10px",
                                }}
                              >
                                {logistics_legend}
                              </h4>
                              <LogisticsMapViewLegend
                                handleWindowClick={setSelectedMapMarker}
                                activeList={activeList}
                                toggleChange={toggleChange}
                                selectAll={selectAll}
                              />
                            </div>
                          </div>

                          <MapWithMarkers
                            data={data}
                            selectedItem={selectedMapMarker}
                            setSelectedItem={setSelectedMapMarker}
                            setNewClass={setNewClass}
                            handleCardClick={handleCardClick}
                            mapCenter={mapCenter}
                            googleMapURL={GOOGLE_MAP_URL}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={
                              <div
                                style={{
                                  height: "1000px",
                                  marginLeft: "15px",
                                  marginRight: "-15px",
                                }}
                              />
                            }
                            mapElement={<div style={{ height: `100%` }} />}
                            activeList={activeList}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <DataNotFound />
              )}
            </div>
          ) : (
            <>
              {filteredData.length === 0 ? (
                <DataNotFound />
              ) : (
                <div className="card-container-logistics-grid">
                  {paginatedData.map((item, index) => (
                    <LogisticsMapViewGrid
                      key={index}
                      {...item}
                      onClick={() => handleCardClick(item)}
                    />
                  ))}
                </div>
              )}
            </>
          )}
          {selectedCard && (
            <div className="card-logistics-overlay">
              <div className="card-logistics-detail-card">
                <div className="mapPopup-close">
                  <button
                    className="closeBtnPopup-close"
                    onClick={closeDetailCard}
                  >
                    X
                  </button>
                </div>

                <h3 className="title-card-view" onClick={closeDetailCard}>
                  {selectedCard.facility_name}
                </h3>
                <div className="scrollable-content">
                  <div>📍 {selectedCard.address}</div>

                  {/* Clickable phone number */}
                  <div>
                    📞{facility_phone_number} : {""}
                    <a
                      href={`tel:${selectedCard.phone}`}
                      style={{ color: "#0066cc", textDecoration: "none" }}
                    >
                      {selectedCard.phone}
                    </a>
                  </div>

                  {selectedCard.business_phone && (
                    <div>
                      📠 {business_phone_number_text} : {""}
                      <a
                        href={`tel:${selectedCard.business_phone}`}
                        style={{ color: "#0066cc", textDecoration: "none" }}
                      >
                        {selectedCard.business_phone}
                      </a>
                    </div>
                  )}
                  {/* Clickable email */}
                  {selectedCard.email && (
                    <div className="nextline-view">
                      ✉️{facility_email_id} : {""}
                      <a
                        href={`mailto:${selectedCard.email}`}
                        style={{ color: "#0066cc", textDecoration: "none" }}
                      >
                        {selectedCard.email}
                      </a>
                    </div>
                  )}

                  {/* Clickable website */}
                  {selectedCard.website && (
                    <div className="nextline-view">
                      🌐{" "}
                      <a
                        href={handleWebsiteURL(selectedCard.website)}
                        target="_blank"
                        rel="noreferrer"
                        style={linkStyle}
                      >
                        {selectedCard.website}
                      </a>
                    </div>
                  )}
                  {selectedCard.point_of_contact && (
                    <div>
                      👤 {point_of_contact_text} : {""}
                      {selectedCard.point_of_contact}
                    </div>
                  )}
                  <div>
                    📦 {facility_type} : {""} {selectedCard.type}
                  </div>
                  {selectedCard.additional_features && (
                    <div>
                      🛠️{additional_features_text} : {""}
                      {selectedCard.additional_features}
                    </div>
                  )}
                  {selectedCard.description && (
                    <div>
                      📝 {description_text} : {""}
                      {selectedCard.description}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {!isMapView && filteredData.length > pageSize ? (
            <div className="pagination-controls">
              <button
                className="btn btn-primary"
                onClick={prevPage}
                disabled={currentPage === 1}
              >
                {languageJson.LogisticsMap.preview_txt}
              </button>
              <span>
                {" "}
                Page {currentPage} of {totalPages}{" "}
              </span>
              <button
                className="btn btn-primary"
                onClick={nextPage}
                disabled={currentPage === totalPages}
              >
                {languageJson.LogisticsMap.next_txt}
              </button>
            </div>
          ) : (
            <></>
          )}
        </section>
      </div>
    </div>
  );
};

export default LogisticsMapViewMain;
