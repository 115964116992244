import React from "react";
import icon1 from "../assets/shipwhite.png";
import icon11 from "../assets/shipblue.png";
import icon3 from "../assets/holidayschedulewhite.png";
import icon2 from "../assets/cctvwhite.png";
import icon4 from "../assets/railcrossingwhite.png";
import icon8 from "../assets/trafficinformationwhite.png";
import icon9 from "../assets/logisticsshipwhite.png";
import blueIcon2 from "../assets/cctvblue.png";
import blueIcon3 from "../assets/holidayscheduleblue.png";
import blueIcon4 from "../assets/railcrossingblue.png";
import blueIcon8 from "../assets/trafficinformationblue.png";
import blueIcon9 from "../assets/logisticsshipblue.png";
import blueTruck from "../assets/trucksblue.png";
import whiteTruck from "../assets/truckswhite.png";
import eriIcon1 from "../assets/eriwhite.png";
import eriIcon11 from "../assets/eriblue.png";
import historicalIcon1 from "../assets/historicalIconWhite.png";
import historicalIcon11 from "../assets/historicalIconBlue.png";

import { Link } from "react-router-dom";
import "../App.css";
import { languageJson } from "../StringVariable";
class Tools extends React.Component {
  state = {
    firstIcon: false,
    secondIcon: false,
    thirdIcon: false,
    fourthIcon: false,
    fifthIcon: false,
    sixthIcon: false,
    seventhIcon: false,
    eigthIcon: false,
    eriIcon: false,
  };

  render() {
    return (
      <>
        <section className="tools-section">
          <div className="row">
            <div className="col-12  center-head-underline d-flex justify-content-center py-5">
              <h1 className="text-center  f-head">
                {languageJson.sectionName["tolls&services"]}
              </h1>
            </div>
          </div>
          <div className="container-fluid p-5 pt-5 min-vh-75 tools-background-image color-overlay">
            <div className="container">
              <div className="row pb-5 tools-row1 pt-5">
                <div className="col-md-3  col-xs-12 col-sm-6">
                  <center className="">
                    <Link to="/vesselOperation" className="decoration_none">
                      {" "}
                      <div
                        className="toolshover"
                        onMouseOver={() => {
                          this.setState({ firstIcon: true });
                        }}
                        onMouseOut={() => {
                          this.setState({ firstIcon: false });
                        }}
                      >
                        <div>
                          <div className="card1">
                            {this.state.firstIcon ? (
                              <img
                                className="pb-4 toolsandservicelogo transition__img"
                                src={icon11}
                                alt="pic"
                              />
                            ) : (
                              <img
                                className="pb-4 toolsandservicelogo transition__img"
                                src={icon1}
                                alt="pic"
                              />
                            )}
                            <h6 className="text-light toolshover">
                              {languageJson.vesselOperation}
                            </h6>
                            <p className="pb-4 text-light toolshover hoverToolDescp tools-text-center">
                              {languageJson.vesselOperationDes}
                            </p>
                          </div>
                          {this.state.firstIcon ? (
                            <p className="pb-4 text-light toolshover desktopToolDescp tools-text-center">
                              {languageJson.vesselOperationDes}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </Link>
                  </center>
                </div>
             
                <div className="col-md-3 col-xs-12 col-sm-6">
                  <center>
                    <Link
                      to={{
                        pathname: "/LogisticsMap",
                        // state: "historicalTruckTurnTime"
                      }}
                    >
                      <div
                        className="toolshover"
                        onMouseOver={() => {
                          this.setState({ fifthIcon: true });
                        }}
                        onMouseOut={() => {
                          this.setState({ fifthIcon: false });
                        }}
                      >
                        <div>
                          <div className="card1">
                            {this.state.fifthIcon ? (
                              <img
                                className="pb-4 toolsandservicelogo transition__img"
                                src={blueIcon9}
                                alt="pic"
                              />
                            ) : (
                              <img
                                className="pb-4 toolsandservicelogo"
                                src={icon9}
                                alt="pic"
                              />
                            )}
                            <h6 className="text-light toolshover">
                              {" "}
                              {
                                languageJson.LogisticsMap
                                  .logistics_map_title_txt
                              }
                            </h6>
                            <p className="pb-4 text-light toolshover hoverToolDescp tools-text-center">
                              {
                                languageJson.LogisticsMap
                                  .logistics_map_title_desc
                              }
                            </p>
                          </div>
                          {/*     "historicalTurnTimeDescription": "View historical truck turn time information for each of the marine terminal gates at the Port of Oakland. Each record can be filtered based on hour, day, week, month or year.",
                           */}
                          {this.state.fifthIcon ? (
                            <p className="pb-4 text-light toolshover desktopToolDescp tools-text-center">
                              {
                                languageJson.LogisticsMap
                                  .logistics_map_title_desc
                              }
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </Link>
                  </center>
                </div>

                <div className="col-md-3 col-xs-12 col-sm-6">
                  <center>
                    <Link to="/PortHolidayCalendar">
                      {" "}
                      <div
                        className="toolshover"
                        onMouseOver={() => {
                          this.setState({ sixthIcon: true });
                        }}
                        onMouseOut={() => {
                          this.setState({ sixthIcon: false });
                        }}
                      >
                        <div>
                          <div className="card1">
                            {this.state.sixthIcon ? (
                              <img
                                className="pb-4 toolsandservicelogo transition__img"
                                src={blueIcon3}
                                alt="pic"
                              />
                            ) : (
                              <img
                                className="pb-4 toolsandservicelogo"
                                src={icon3}
                                alt="pic"
                              />
                            )}
                            <h6 className="text-light toolshover">
                              {languageJson.port_holiday_calendar_title}
                            </h6>
                            <p className="pb-4 text-light toolshover hoverToolDescp tools-text-center">
                              {languageJson.port_holiday_calendar_desc}
                            </p>
                          </div>
                          {this.state.sixthIcon ? (
                            <p className="pb-4 text-light toolshover desktopToolDescp tools-text-center">
                              {languageJson.port_holiday_calendar_desc}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </Link>
                  </center>
                </div>


                <div className="col-md-3 col-xs-12 col-sm-6">
                  <center>
                    <Link to="/trafficCameras" className="decoration_none">
                      <div
                        className="toolshover"
                        onMouseOver={() => {
                          this.setState({ secondIcon: true });
                        }}
                        onMouseOut={() => {
                          this.setState({ secondIcon: false });
                        }}
                      >
                        <div>
                          <div className="card1">
                            {this.state.secondIcon ? (
                              <img
                                className="pb-4 toolsandservicelogo transition__img"
                                src={blueIcon8}
                                alt="pic"
                              />
                            ) : (
                              <img
                                className="pb-4 toolsandservicelogo"
                                src={icon8}
                                alt="pic"
                              />
                            )}
                            <h6 className="text-light toolshover">
                              {languageJson["traffic&Cameras"]}
                            </h6>
                            <p className="pb-4 text-light toolshover hoverToolDescp tools-text-center">
                              {languageJson["traffic&CamerasDes"]}
                            </p>
                          </div>
                          {this.state.secondIcon ? (
                            <p className="pb-4  toolshover text-white  desktopToolDescp tools-text-center">
                              {languageJson["traffic&CamerasDes"]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </Link>
                  </center>
                </div>
               
              </div>

              <div className="row pt-5 mt-3 tooldivTwo">
                <div className="col-md-3 col-xs-12 col-sm-6 ">
                  <center>
                    <Link to="/cameras" className="decoration_none">
                      <div
                        className="toolshover"
                        onMouseOver={() => {
                          this.setState({ eigthIcon: true });
                        }}
                        onMouseOut={() => {
                          this.setState({ eigthIcon: false });
                        }}
                      >
                        <div>
                          <div className="card1">
                            {this.state.eigthIcon ? (
                              <img
                                className="pb-4 toolsandservicelogo transition__img"
                                src={blueIcon2}
                                alt="pic"
                              />
                            ) : (
                              <img
                                className="pb-4 toolsandservicelogo"
                                src={icon2}
                                alt="pic"
                              />
                            )}
                            <h6 className="text-light toolshover">
                              {languageJson.truckRoutes}
                            </h6>
                            <p className="pb-4 text-light toolshover hoverToolDescp tools-text-center">
                              {languageJson.truckRoutesDes}
                            </p>
                          </div>
                          {this.state.eigthIcon ? (
                            <p className="pb-4 text-light toolshover desktopToolDescp tools-text-center">
                              {languageJson.truckRoutesDes}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </Link>
                  </center>
                </div>
                <div className="col-md-3 col-xs-12 col-sm-6">
                  <center>
                    <Link to="/railwayCrossing">
                      {" "}
                      <div
                        className="toolshover"
                        onMouseOver={() => {
                          this.setState({ fourthIcon: true });
                        }}
                        onMouseOut={() => {
                          this.setState({ fourthIcon: false });
                        }}
                      >
                        <div>
                          <div className="card1">
                            {this.state.fourthIcon ? (
                              <img
                                className="pb-4 toolsandservicelogo transition__img"
                                src={blueIcon4}
                                alt="pic"
                              />
                            ) : (
                              <img
                                className="pb-4 toolsandservicelogo"
                                src={icon4}
                                alt="pic"
                              />
                            )}
                            <h6 className="text-light toolshover">
                              {languageJson.railCrossing}
                            </h6>
                            <p className="pb-4 text-light toolshover hoverToolDescp tools-text-center">
                              {languageJson.railCrossingDes}
                            </p>
                          </div>
                          {this.state.fourthIcon ? (
                            <p className="pb-4 text-light toolshover desktopToolDescp tools-text-center">
                              {languageJson.railCrossingDes}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </Link>
                  </center>
                </div>

               

   <div className="col-md-3  col-xs-12 col-sm-6">
                  <center className="">
                  <Link to={{
                      pathname: "/ViewTruckTurnTimes",
                      state: "historicalTruckTurnTime"
                    }}>  
                    {/* <Link to="/eri" className="decoration_none"> */}
                      {" "}
                      <div
                        className="toolshover"
                        onMouseOver={() => {
                          this.setState({ eriIcon: true });
                        }}
                        onMouseOut={() => {
                          this.setState({ eriIcon: false });
                        }}
                      >
                        <div>
                          <div className="card1">
                            {this.state.eriIcon ? (
                              <img
                                className="pb-4 toolsandservicelogo transition__img"
                                src={historicalIcon11}
                                alt="pic"
                              />
                            ) : (
                              <img
                                className="pb-4 toolsandservicelogo transition__img"
                                src={historicalIcon1}
                                alt="pic"
                              />
                            )}
                            <h6 className="text-light toolshover">
                              {languageJson.Historical_txt}
                            </h6>
                            <p className="pb-4 text-light toolshover hoverToolDescp tools-text-center">
                              {
                                languageJson.historicalTurnTimeDescription
                              }
                            </p>
                          </div>
                          {this.state.eriIcon ? (
                            <p className="pb-4 text-light toolshover desktopToolDescp tools-text-center">
                              {
                                languageJson.historicalTurnTimeDescription
                              }{/* "View specific return instruction details for motor carriers." */}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </Link>
                  </center>
                </div>
                <div className="col-md-3 col-xs-12 col-sm-6">
                  <center>
                    <Link
                      to="/TruckRoutesComplianceInformation"
                      className="decoration_none"
                    >
                      <div
                        className="toolshover"
                        onMouseOver={() => {
                          this.setState({ thirdIcon: true });
                        }}
                        onMouseOut={() => {
                          this.setState({ thirdIcon: false });
                        }}
                      >
                        <div>
                          <div className="card1">
                            {this.state.thirdIcon ? (
                              <img
                                className="pb-4 toolsandservicelogo transition__img"
                                src={blueTruck}
                                alt="pic"
                              />
                            ) : (
                              <img
                                className="pb-4 toolsandservicelogo"
                                src={whiteTruck}
                                alt="pic"
                              />
                            )}
                            <h6 className="text-light toolshover">
                              {languageJson["security&safety"]}
                            </h6>
                            <p className="pb-4 text-light toolshover hoverToolDescp tools-text-center">
                              {languageJson["security&safetyDes"]}
                            </p>
                          </div>
                          {this.state.thirdIcon ? (
                            <p className="pb-4 text-light toolshover desktopToolDescp tools-text-center">
                              {languageJson["security&safetyDes"]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </Link>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Tools;
