/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../../Containers/Header";
import { Link } from "react-router-dom";
import { languageJson } from "../../StringVariable";
import "./eri.css";
import { GET_DATAHUB_ERI_LIST } from "src/url/Url";
import api from "src/redux/actions/apis";
import DataNotFound from "../DataNotFound/DataNotFound";
import SpinerLoader from "src/utils/SpinerLoader";
import { submitGAEvent } from "src/utils/GAEvents";
import portimg1 from "../../assets/RailCrossing/1.jpg";
import portimg2 from "../../assets/RailCrossing/2.jpg";
import portimg3 from "../../assets/RailCrossing/3.jpg";

import { Accordion, Card } from "react-bootstrap";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CCardSubtitle,
  CCardText,
} from "@coreui/react";
import moment from "moment";

function ERI() {
  const {
    eri_select_shipping_line,
    eri_select_shipping_terminal,
    eri_select_shipping_window,
    eri_party_information,
    eri_facility_name,
    eri_facility_type,
    eri_code,
    eri_port,
    eri_address,
    eri_time,
    eri_notes,
    eri_has_pending_reviews,
    eri_equipment_sizes,
    eri_availability_time_window,
  } = languageJson.ERI_Value;

  const timeWindowList = [
    { id: "", value: "All" },
    { id: "First Shift", value: "First Shift" },
    { id: "Second Shift", value: "Second Shift" },
  ];
  let headersKeys = ["Equipment Type", "Open", "Blocked"];

  const [list, setList] = useState(null);
  const [initList, setInitList] = useState(null);
  const [paginatedData, setPaginatedData] = useState(null);
  const [shippingLine, setShippingLine] = useState("");
  const [allShippingLines, setAllShippingLines] = useState([]);
  const [allDataTerminals, setAllDataTerminals] = useState([]);
  const [terminal, setTerminal] = useState("");
  const [timeWindow, setTimeWindow] = useState("");

  const [active, setActive] = useState();
  const [isSearch, setIsSearch] = useState(false);

  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [totalPages, setTotalPages] = useState(0); // Current page number
  const pageSize = 10; // Items per page

  useEffect(() => {
    submitGAEvent("ERI");
  }, []);

  const getEriList = async () => {
    try {
      setIsSearch(true);
      await api.get(GET_DATAHUB_ERI_LIST).then((res) => {
        const { customcode, data } = res.data;
        if (customcode === 200) {
          setIsSearch(false);
          const shippingExist = new Set();
          const terminalExist = new Set();
          let shippingArray = [];
          let terminalArray = [];
          data.forEach((item) => {
            if (!shippingExist.has(item.shippingLine.name)) {
              shippingExist.add(item.shippingLine.name);
              shippingArray.push({
                id: item.shippingLine.id,
                name: item.shippingLine.name,
              });
            }
            if (!terminalExist.has(item.party.name)) {
              terminalExist.add(item.party.name);
              terminalArray.push({
                id: item.party.code,
                name: item.party.name,
              });
            }
          });
          setAllShippingLines(shippingArray);
          setAllDataTerminals(terminalArray);
          setInitList(data);
          setList(data);
          const totalPages = data && Math.ceil(data.length / pageSize);
          setTotalPages(totalPages)
          const paginatedData = data && data.slice(0,pageSize);
          setPaginatedData(paginatedData);
        } else {
          setIsSearch(false);
          setInitList([]);
          setList([]);
          setPaginatedData([]);
        }
      });
    } catch (err) {
      setIsSearch(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getEriList();
  }, []);

  const onSearchClick = async () => {
    setIsSearch(true);
    const filteredData =
      initList &&
      initList
        .filter((item) => item !== null) // Remove null items if targetDate was applied
        .filter(
          (item) =>
            (!shippingLine ||
              item.shippingLine.name.toLowerCase() ===
                shippingLine.toLowerCase()) &&
            (!terminal ||
              item.party.name.toLowerCase() === terminal.toLowerCase())
        );
    setIsSearch(false);
    setList(filteredData);
    setCurrentPage(1); // Current page number
    const totalPages = filteredData && Math.ceil(filteredData.length / pageSize);
    setTotalPages(totalPages)
    const paginatedData = filteredData && filteredData.slice(0,pageSize);
    setPaginatedData(paginatedData);
  };

  const decoratedOnClick = (active1) => {
    var element1 = document.getElementsByClassName("inner-acc collapse show");
    if (element1[0] && element1[0].classList) {
      element1[0].classList.remove("show");
    }
    if (active === active1) {
      setActive(null);
    } else {
      setActive(active1);
    }
  };
   
  const paginationFun = (currentPageValue) =>{
    const totalPages = list && Math.ceil(list.length / pageSize);
    const paginatedData = list && list.slice(
      (currentPageValue - 1) * pageSize,
      currentPageValue * pageSize
    );
    setTotalPages(totalPages)
    setPaginatedData(paginatedData);
  
  }

    const nextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
        paginationFun(currentPage + 1);
      }
    };
  
    const prevPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        paginationFun(currentPage - 1);
      }
    };


  let renderAccordion = (dataItem, index) => {
    return (
      <Card key={index}>
        <Accordion.Toggle
          as={Card.Header}
          eventKey={`${index}`}
          onClick={() => decoratedOnClick(index)}
          className={parseInt(active) === index ? "active active1" : ""}
        >
          <h5>
            {dataItem.shippingLine.name} ({dataItem.shippingLine.code})
          </h5>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={`${index}`}>
          <Card.Body>
            <CTable bordered striped>
              <React.Fragment>
                {dataItem.partyTimeWindowCapacities.map(
                  (childItem, indexChildItem) => {
                    const facilityAddress = dataItem.party.facilityAddress;
                    const addressData = JSON.parse(facilityAddress);
                    const formattedAddress = [
                      addressData.Address,
                      addressData.City,
                      addressData.State,
                      addressData.Country,
                      addressData.Zip,
                    ]
                      .filter(Boolean)
                      .join(", ");
                    return (
                      <React.Fragment>
                        <CTableHead key={indexChildItem}>
                          {indexChildItem === 0 && (
                            <React.Fragment>
                              <CTableRow>
                                <CTableHeaderCell colSpan={12}>
                                  <CCardSubtitle
                                    style={{ display: "flex", width: "100%" }}
                                  >
                                    {eri_party_information}
                                  </CCardSubtitle>
                                  <CCardText
                                    style={{ display: "flex", width: "100%" }}
                                  >
                                    <ul>
                                      <li>
                                        <span
                                          style={{
                                            fontSize: "1.2em",
                                            marginRight: "10px",
                                          }}
                                        >
                                          &#8226;
                                        </span>
                                        {eri_facility_name}:
                                        <span style={{ fontWeight: "100" }}>
                                          {" "}
                                          {dataItem.party.name}
                                        </span>
                                      </li>
                                      <li>
                                        <span
                                          style={{
                                            fontSize: "1.2em",
                                            marginRight: "10px",
                                          }}
                                        >
                                          &#8226;
                                        </span>
                                        {eri_facility_type}:
                                        <span style={{ fontWeight: "100" }}>
                                          {" "}
                                          {dataItem.party.facilityType}
                                        </span>
                                      </li>
                                      <li>
                                        <span
                                          style={{
                                            fontSize: "1.2em",
                                            marginRight: "10px",
                                          }}
                                        >
                                          &#8226;
                                        </span>
                                        {eri_code}:
                                        <span style={{ fontWeight: "100" }}>
                                          {" "}
                                          {dataItem.party.code}
                                        </span>
                                      </li>
                                      <li>
                                        <span
                                          style={{
                                            fontSize: "1.2em",
                                            marginRight: "10px",
                                          }}
                                        >
                                          &#8226;
                                        </span>
                                        {eri_port}:
                                        <span style={{ fontWeight: "100" }}>
                                          {" "}
                                          {dataItem.party.port}
                                        </span>
                                      </li>
                                      <li>
                                        <span
                                          style={{
                                            fontSize: "1.2em",
                                            marginRight: "10px",
                                          }}
                                        >
                                          &#8226;
                                        </span>
                                        {eri_address}: 
                                        <span style={{ fontWeight: "100" }}>
                                        {" "}
                                          {formattedAddress}
                                        </span>
                                      </li>
                                    </ul>
                                  </CCardText>
                                </CTableHeaderCell>
                              </CTableRow>
                            </React.Fragment>
                          )}

                          {(childItem.description === timeWindow ||
                            timeWindow === "") && (
                            <CTableRow>
                              <CTableHeaderCell colSpan={12}>
                                <CCardSubtitle
                                  style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    padding: "20px",
                                  }}
                                >
                                  {eri_availability_time_window}{" "}
                                  {indexChildItem + 1} : {childItem.description}{" "}
                                </CCardSubtitle>
                                <CTable bordered>
                                  <CTableHead>
                                    <CTableRow color="dark">
                                      <CTableHeaderCell scope="col">
                                        {eri_time}
                                      </CTableHeaderCell>
                                      <CTableHeaderCell scope="col">
                                        {eri_notes}
                                      </CTableHeaderCell>
                                      <CTableHeaderCell scope="col">
                                        {eri_has_pending_reviews}
                                      </CTableHeaderCell>
                                    </CTableRow>
                                  </CTableHead>
                                  <CTableBody>
                                    <CTableRow style={{ fontWeight: "100" }}>
                                      <CTableDataCell>
                                        {moment(childItem.startTime).format(
                                          "MM/DD/YYYY hh:mm A"
                                        )}{" "}
                                        -{" "}
                                        {moment(childItem.endTime).format(
                                          "MM/DD/YYYY hh:mm A"
                                        )}
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {childItem.notes || "N/A"}
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {childItem.hasPendingReviews === true
                                          ? "Yes"
                                          : "No"}
                                      </CTableDataCell>
                                    </CTableRow>
                                  </CTableBody>
                                </CTable>
                                <CCardSubtitle>
                                  {eri_equipment_sizes}{" "}
                                </CCardSubtitle>
                              </CTableHeaderCell>
                            </CTableRow>
                          )}
                        </CTableHead>
                        {(childItem.description === timeWindow ||
                          timeWindow === "") &&
                          headersKeys.map((header, indexHeader) => {
                            return (
                              <React.Fragment>
                                <CTableBody>
                                  <CTableRow>
                                    <CTableHeaderCell>
                                      {header}
                                    </CTableHeaderCell>
                                    {childItem.equipmentCapacities.map(
                                      (dataItem, indexDataItem) => {
                                        return (
                                          <React.Fragment>
                                            {header === "Equipment Type" && (
                                              <CTableHeaderCell
                                                style={{ textAlign: "center" }}
                                              >
                                                {dataItem["equipmentSizeType"]}
                                              </CTableHeaderCell>
                                            )}
                                            {header === "Open" && (
                                              <CTableDataCell
                                                color={
                                                  dataItem["isOpen"] === true
                                                    ? "success"
                                                    : "danger"
                                                }
                                                style={{ textAlign: "center" }}
                                              >
                                                {dataItem["isOpen"] === true
                                                  ? "Yes"
                                                  : "No"}
                                              </CTableDataCell>
                                            )}
                                            {header === "Blocked" && (
                                              <CTableDataCell
                                                color={
                                                  dataItem["isBlocked"] === true
                                                    ? "success"
                                                    : "danger"
                                                }
                                                style={{ textAlign: "center" }}
                                              >
                                                {dataItem["isBlocked"] === true
                                                  ? "Yes"
                                                  : "No"}
                                              </CTableDataCell>
                                            )}
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </CTableRow>
                                </CTableBody>
                              </React.Fragment>
                            );
                          })}
                      </React.Fragment>
                    );
                  }
                )}
              </React.Fragment>
            </CTable>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };

  return (
    <div>
      <Header />
      <div className="image-row-banner">
        <img className="banner_img" src={portimg1} alt="" />
        <img className="banner_img" src={portimg3} alt="" />
        <img className="banner_img" src={portimg2} alt="" />
      </div>
      <div className="vo-bg">
        <div className="container-fluid row py-4 ">
          <div>
            <Link to="/">
              {" "}
              <span className="vo-menu pe-2">
                {languageJson.home_txt}{" "}
              </span>{" "}
            </Link>
            <span className="text-dark ps-2">
              {" "}
              / &nbsp; {languageJson.empty_return_instructions}
            </span>
          </div>
        </div>
      </div>

      {/* ------------------------------------------------------ */}
      <div className="">
        <section className="container py-5">
          <div className="row container-fluid py-5 tabVesselDiv">
            <div className="d-flex justify-content-center">
              <div className="truckTurnTimeMainDiv historicalTTTMainDiv">
                <div className="container">
                  <div className="titleDescpDiv">
                    <h2>{languageJson.empty_return_instructions}</h2>
                  </div>
                  <div className="col-12 lastUpdateDiv">
                    <div className="col-12 lastUpdateTitleDiv">
                      <h3 className="col-12"> </h3>
                    </div>
                    <div className="col-12 listOptionDiv">
                      <div className="row">
                        <div className="col-md-6">
                          {" "}
                          <label>{eri_select_shipping_line}</label>
                          <select
                            selected={"All"}
                            onChange={(e) => setShippingLine(e.target.value)}
                            className="browser-default custom-select"
                          >
                            <option value="">{languageJson.all_txt}</option>
                            {allShippingLines != null &&
                              allShippingLines &&
                              allShippingLines.map((lineEle, index) => {
                                return (
                                  <option value={lineEle.name} key={index}>
                                    {lineEle.name}{" "}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="col-md-6">
                          {" "}
                          <label>{eri_select_shipping_terminal}</label>
                          <select
                            selected={"All"}
                            onChange={(e) => setTerminal(e.target.value)}
                            className="browser-default custom-select"
                          >
                            <option value="">{languageJson.all_txt}</option>
                            {allDataTerminals != null &&
                              allDataTerminals &&
                              allDataTerminals.map((terminal, index) => {
                                return (
                                  <option value={terminal.name} key={index}>
                                    {terminal.name}{" "}
                                  </option>
                                );
                              })}
                          </select>
                        </div>

                        <div className="col-md-6">
                          {" "}
                          <label>{eri_select_shipping_window}</label>
                          <select
                            selected={"All"}
                            onChange={(e) => setTimeWindow(e.target.value)}
                            className="browser-default custom-select"
                          >
                            {timeWindowList.map((timeele, index) => {
                              return (
                                <option value={timeele.id} key={index}>
                                  {timeele.value}{" "}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-md-6">
                          <button
                            className="btn btn-primary"
                            onClick={onSearchClick}
                          >
                            <i className="fa fa-search" aria-hidden="true"></i>{" "}
                            {languageJson.search_txt}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {list && list !== null && list.length > 0 ? (
            <div className="allAlertsMainDiv ">
              <div className="eriDiv">
                <Accordion>
                  {paginatedData && paginatedData.map((item, index) => {
                    return renderAccordion(item, index);
                  })}
                </Accordion>
              </div>
            </div>
          ) : list && list !== null && list.length === 0 ? (
            <DataNotFound />
          ) : (
            isSearch && <SpinerLoader />
          )}
          {list && list !== null && list.length > pageSize ? (
            <div className="pagination-controls">
              <button
                className="btn btn-primary"
                onClick={prevPage}
                disabled={currentPage === 1}
              >
                {languageJson.LogisticsMap.preview_txt}
              </button>
              <span>
                {" "}
                Page {currentPage} of {totalPages}{" "}
              </span>
              <button
                className="btn btn-primary"
                onClick={nextPage}
                disabled={currentPage === totalPages}
              >
                {languageJson.LogisticsMap.next_txt}
              </button>
            </div>
          ) : (
            <></>
          )}
        </section>
      </div>
    </div>
  );
}

export default ERI;
